var React           = require('react'),
    connect         = require('react-redux').connect,
    actions         = require('../../actions'),

    FooterSecurity;

FooterSecurity = React.createClass({
    home: function (e) {
        e.preventDefault();
        this.props.dispatch(actions.stages.goHome());
    },

    render: function() {
        var content = this.props.content;

        return (<footer className='footer-security'>
            <div className='footer-security__inner'>
                <div className='footer-security__left'>
                    <div className='footer-security__text'>&copy; {new Date().getFullYear()} {content.footerCopyrightText}</div>
                    <div className='footer-security__images-wrapper'>
                        <img src='/img/joinonline/verify-security.png' width="264" height="62" />
                        <img src='/img/joinonline/cyber-essentials-logo.png' width="77" height="30" />
                    </div>
                </div>
                <div className='footer-security__right'>
                    <div className='footer-security__text'>Card Payments via Cardstream</div>
                    <img src='https://s3-eu-west-2.amazonaws.com/prod-everyoneactive-wp/wp-content/uploads/2018/03/10232625/card-logos-e1548064178928.png' alt='Card Payments'/>
                </div>
            </div>
        </footer>);
    }
});

function mapStateToProps(state) {
    return {
        content: state.app.content.footer
    };
}

module.exports = connect(mapStateToProps)(FooterSecurity);
