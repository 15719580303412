var debug                   = require('debug')('actions-lessons'),
    appActions              = require('./app'),
    selectionsActions       = require('./selections.js'),
    personalDetailsActions  = require('./personalDetails.js'),
    actionTypes             = require('./actionTypes.js'),
    httpFetch               = require('../utils/httpFetch'),
    centreModel             = require('../models/centre'),
    userModel               = require('../models/user'),
    config                  = require('../config'),
    utils                   = require('../utils'),

    timeout,
    exports;

module.exports = exports = {

    ageValidation: {
        default: {
            min: 0,
            max: 999
        },
        multiswimming: {
            min: 0,
            max: 999
        }
    },

    userValidationRules: {
        firstName: {
            required: true,
            validators: {
                isAlpha: true
            }
        },
        dob: {
            required: true,
            range: true // Range to be given -> first sub?
        }
    },

    addClasses: function addClasses(classes, levelInfo) {
        return {
            type: actionTypes.LESSONS.ADD_CLASSES,
            classes: classes,
            levelInfo: levelInfo
        };
    },

    addNewLevel: function addNewLevel(level, levelName) {
        return {
            type: actionTypes.LESSONS.UPDATE_SELECTED_LESSONS,
            level: level,
            levelName: levelName
        };
    },

    attachBooking: function attachBooking(user, userId, bookingRef, lesson) {
        return httpFetch.fetch(config.services.lessons.urls.transferSpace, {
            method: 'POST',
            params: {
                booking: bookingRef,
                memberId: user.info.mrmId.value
            },
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
        .then(function(response) {
            if(response.error) {
                return response;
            } else {
                return {
                    booking: response.booking,
                    lesson: lesson,
                    userId: userId
                };
            }
        })
        .catch(function (e) {
            debug('error: ' + JSON.stringify(e));

            return {
                error: e.message
            };
        });
    },

    attachCallError: function attachCallError(e) {
        return {
            type: actionTypes.LESSONS.ATTACH_CALL_ERROR,
            error: e
        };
    },

    changeData: function changeData(data) {
        return function (dispatch, getState) {
            var state = getState(),
                user = JSON.parse(JSON.stringify(state.lessons.user)),
                validatedData = {},
                promises = [],
                infoKey,
                result,
                value,
                i;

            for (var key in data) {
                validatedData.info = {};

                for (infoKey in data.info) {
                    value = data.info[infoKey];

                    if ('dob' === infoKey) {
                        user.info[infoKey] = {
                            value: value,
                            valid: true,
                            validationErrors: {}
                        };

                        result = {
                            age: userModel.getAgeValue(user),
                            ageInMonths: userModel.getAgeInMonths(user),
                            ageRange: exports.getAgeRange(user, userModel.getAgeInMonths(user), state.lessons)
                        };

                        validatedData.info.age = result.age;
                        validatedData.info.ageInMonths = result.ageInMonths;

                        if (data.convertMonthsToYears) {
                            promises.push(userModel.validateField(infoKey, result.age, { range: result.ageRange }));
                        } else {
                            promises.push(userModel.validateField(infoKey, result.ageInMonths, { range: result.ageRange }));
                        }
                    } else {
                        promises.push(userModel.validateField(infoKey, value));
                    }
                }
            }

            if (promises.length) {
                Promise.all(promises)
                    .then(function(results) {
                        for (i = 0; i < results.length; i++) {
                            result = results[i];

                            if ('dob' === result.key) {
                                result.value = data.info[result.key];
                            }

                            validatedData.info[result.key] = result;
                        }

                        if(result.key === 'dob') {
                            dispatch(exports.resetUser(validatedData, result.key));
                        } else {
                            dispatch(exports.updateUser(validatedData, result.key));
                        }
                    });

            }
        };
    },

    clearEligibilityUser: function clearEligibilityUser() {
        return {
            type: actionTypes.LESSONS.CLEAR_ELIGIBILITY_USER
        };
    },

    clearLevels: function clearLevels() {
        return {
            type: actionTypes.LESSONS.CLEAR_LEVELS
        };
    },

    attachCallError: function attachCallError(e, c) {
        return {
            type: actionTypes.LESSONS.ATTACH_CALL_ERROR,
            error: e,
            code: c
        };
    },

    clearSelections: function clearSelections() {
        return {
            type: actionTypes.LESSONS.CLEAR_SELECTIONS
        };
    },

    confirmPayment: function confirmPayment(bookingRef) {
        return function(dispatch) {
            dispatch(appActions.showLoader(true));

            return httpFetch.fetch(config.services.lessons.urls.paid, {
                method: 'POST',
                params: {
                    booking: bookingRef
                },
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            })
            .then(function() {
                dispatch(appActions.showLoader(false));
            })
            .catch(function (e) {
                debug('error: ' + JSON.stringify(e));
                dispatch(appActions.showLoader(false));
            });
        };
    },

    fetchCategoryForStage: function fetchCategoryForStage(levelObj, isStatic, isFallback) {
        return function(dispatch, getState) {
            var state = getState(),
                centre = state.centreFinder.selected,
                user = state.lessons.user;

            //set the user as being eligible by default
            dispatch(exports.updateUser({ineligible: false}));

            //if the user already has a category and is an adult, or we are in generic get adult levels
            if(user.category && /16\+/.test(user.category.toLowerCase()) || user.recommendedLevel === 'generic') {
                dispatch(exports.fetchLevelsForUser(user.category));
                dispatch(exports.updateUser({staticResult: isStatic}));
            } else {
                dispatch(appActions.showLoader(true));

                //this returns the category (Junior, adult, etc.) for the stage that the user is eligible for
                //it it also returns any levels in different course that can be unlocked with this stage
                return httpFetch.fetch(config.services.lessons.urls.categoryForStage, {
                    method: 'POST',
                    params: {
                        centre: centre.info.site_id.replace(/^[0\.]+/, ''),
                        stage: levelObj.level,
                        lessonsOnly: true
                    },
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    }
                })
                .then(function(response) {
                    if(response.category || response.unlocked) {
                        //adding the category and unlocked levels to the user
                        dispatch(exports.updateUser({
                            category: response.category,
                            chosenLevel: isStatic ? user.recommendedLevel : null,
                            staticResult: isStatic,
                            unlockedLevels: response.unlocked
                        }));

                        //get the levels for the category
                        dispatch(exports.fetchLevelsForUser(response.category));

                    //if the stage can't be found, and fallbacks for the stage exist
                    //try again with those

                    //second fall back if exists
                    } else if(isFallback && levelObj.fallback) {
                        levelObj = {
                            level: levelObj.fallback
                        };
                        dispatch(exports.fetchCategoryForStage(levelObj, false));
                        dispatch(exports.updateUser({recommendedLevel: levelObj.level}));

                    //first fallback if exists
                    } else if(levelObj.fallback) {
                        levelObj = {
                            level: levelObj.fallback,
                            fallback: levelObj.fallback2 || null
                        };
                        dispatch(exports.fetchCategoryForStage(levelObj, false, true));
                        dispatch(exports.updateUser({recommendedLevel: levelObj.level}));

                    //the stage is not available at his centre and the user is ineligible
                    } else {
                        dispatch(exports.updateUser({
                            ineligible: true,
                            unlockedLevels: response.unlocked
                        }));
                        dispatch(appActions.showLoader(false));
                    }
                })
                .catch(function (e) {
                    dispatch(exports.updateUser({ineligible: true}));
                    dispatch(appActions.showLoader(false));
                    debug('error: ' + JSON.stringify(e));
                });
            }
        };
    },

    fetchLevelsForUser: function fetchLevelsForUser(categoryId) {




        return function(dispatch, getState) {
            var state = getState(),
                centre = state.centreFinder.selected,
                typeId = state.lessons.typeId;

                const isUserIneligible = state.lessons.eligibilityOptions && state.lessons.eligibilityOptions.min === -1 && state.lessons.eligibilityOptions.max === -1;

            dispatch(appActions.showLoader(true));

            return httpFetch.fetch(config.services.lessons.urls.stagesForUser, {
                method: 'POST',
                params: {
                    centre: centre.info.site_id.replace(/^[0\.]+/, ''),
                    type: typeId,
                    category: categoryId
                },
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            })
            .then(function(response) {
                var user = state.lessons.user,
                    ineligible = false,
                    isAvailable,
                    lessonType,
                    levelName,
                    userData;


                    if (isUserIneligible) {
                        dispatch(exports.updateUser({ineligible: true}));
                        dispatch(appActions.showLoader(false));

                        return null;
                    }

                //check if any unlocked level can be booked by the user at that centre
                if(user.unlockedLevels && user.unlockedLevels.length) {
                    for(var i = 0; i < user.unlockedLevels.length; i++) {
                        for(var r = 0; r < response.length; r++) {
                            if(user.unlockedLevels[i].mrm_stage_id === response[r].mrm_stage_id) {
                                isAvailable = true;
                                break;
                            }
                        }
                    }
                }

                //if the user has chosen a static level
                //or if the results only show 1 level
                //set it as the users chosen level
                if(user.chosenLevel) {
                    // If there is an unlocked level that is available, or there are no unlocked levels
                    if(!user.unlockedLevels || (user.unlockedLevels.length > 0 && isAvailable) || (user.unlockedLevels.length === 0)) {
                        // if(response.length === 1) {
                        //     user.chosenLevel = response[0].mrm_stage_id;
                        // }

                        for(var i = 0; i < response.length; i++) {
                            if(user.chosenLevel === response[i].mrm_stage_id) {
                                levelName = response[i].title;
                                lessonType = response[i].lesson_type_id;
                                break;
                            }
                        }

                        if(!levelName) {
                            ineligible = true;
                        }

                        userData = {
                            chosenLevel: user.chosenLevel,
                            ineligible: ineligible,
                            levelName: levelName,
                            lessonType: lessonType
                        };

                        dispatch(exports.getClassesByLevel(centre, userData));
                        dispatch(exports.setError());
                    } else {
                        dispatch(exports.setError('chosenLevel'));
                        dispatch(appActions.showLoader(false));
                    }

                //if the user category eg. Junior is not empty,
                //and level is not already set
                //get the levels for the user
                } else if((categoryId || user.recommendedLevel === 'generic') && !user.chosenLevel) {
                    dispatch(exports.setLevelsForUser(response, ineligible, levelName));
                    dispatch(appActions.showLoader(false));
                } else {
                    dispatch(exports.updateUser({ineligible: true}));
                    dispatch(appActions.showLoader(false));
                }
            })
            .catch(function (e) {
                debug('error: ' + JSON.stringify(e));
            });
        };
    },

    formatEnquiryForSCV: function formatEnquiryForSCV() {
        return function(dispatch, getState) {
            var state = getState(),
                users = state.selections.users.objects,
                centre = state.centreFinder.selected,
                leadUser = users.find(function(u) { return u.lead; });

            for(var i = 0; i < users.length; i++) {
                var user = users[i];

                if(user.info && user.info.medicalFollowup && user.info.medicalFollowup.value) {
                    var email, telephone;

                    if(user.useLeadContact) {
                        // Only replace email if it is blank
                        if (!user.info.email || !user.info.email.value) {
                            email = leadUser.info.email && leadUser.info.email.value;
                        } else {
                            email = user.info.email.value;
                        }
                        telephone = leadUser.info.telephone && leadUser.info.telephone.value;
                    } else {
                        email = user.info.email && user.info.email.value;
                        telephone = user.info.telephone && user.info.telephone.value;
                    }

                    var params = {
                        siteId: centre.info.site_id,
                        firstname: user.info.firstName && user.info.firstName.value,
                        lastname: user.info.lastName && user.info.lastName.value,
                        email: email,
                        phone: telephone
                    };

                    dispatch(exports.submitEnquiryToSCV(params, i));
                }
            }
        };
    },

    fetchTimetable: function fetchTimetable(typeId, centreId, availability) {
        return function(dispatch) {
            var promises = [],
                urls = [
                    config.services.lessons.urls.timetable,
                    config.services.lessons.urls.prices
                ];

            for(var i = 0; i < urls.length; i++) {
                promises.push(httpFetch.fetch(urls[i], {
                    method: 'POST',
                    params: {
                        type: typeId,
                        centre: centreId,
                        availability: availability
                    },
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    }
                }));
            }

            Promise.all(promises)
                .then(function(responses) {
                    var timetable = responses[0],
                        prices = responses[1];

                    dispatch(exports.setTimetable(timetable, prices, availability));
                    dispatch(appActions.showLoader(false));
                })
                .catch(function(e) {
                    dispatch(appActions.showLoader(false));
                    debug('error: ' + JSON.stringify(e));
                });
        };
    },

    fetchTypes: function fetchTypes(defaultLessonTypeId) {
        return function(dispatch) {
            dispatch(appActions.showLoader(true));

            return httpFetch.fetch(config.services.lessons.urls.lessonTypes, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            })
            .then(function(response) {
                dispatch(exports.getTypes(response));

                //default it to swimming
                let lessonId = defaultLessonTypeId || response.reduce((lessonId, currentLesson) => {
                    return currentLesson.name.toLowerCase() === 'swimming' ? currentLesson.id : lessonId;
                }, null);

                if (lessonId) {
                    dispatch(exports.setType(lessonId));
                } else if (response[0] &&  response[0].id) {
                    dispatch(exports.setType(response[0].id));
                }
                dispatch(appActions.showLoader(false));
            })
            .catch(function (e) {
                debug('error: ' + JSON.stringify(e));
            });
        };
    },

    fetchCentreTypes: function fetchCentreTypes(centre) {
        return function(dispatch) {
            dispatch(appActions.showLoader(true));

            return httpFetch.fetch(config.services.lessons.urls.lessonTypes, {
                method: 'GET',
                params: {
                    centre: centre || null
                },
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            })
            .then(function(response) {
                dispatch(exports.setCentreTypes(response));
                dispatch(appActions.showLoader(false));
            })
            .catch(function (e) {
                debug('error: ' + JSON.stringify(e));
            });
        };
    },

    getAgeRange: function getAgeRange(user, ageInMonths, lessons) {
        var lessonName;

        if(lessons.isMulti && lessons.category) {
            lessonName = lessons.category;
        } else {
            for(var l in lessons.typesList) {
                if(lessons.typesList[l]['id'] === parseInt(lessons.typeId)) {
                    lessonName = lessons.typesList[l]['name'].toLowerCase().replace(/\s/g, '');
                    break;
                }
            }
        }

        var validation = this.ageValidation[lessonName] || this.ageValidation['default'];
        return {
            min: validation.min,
            max: validation.max
        };
    },

    getClassesByLevel: function getClassesByLevel(centre, userData) {
        return function(dispatch, getState) {
            var state = getState();

            if(!state.lessons.levelInfo || (state.lessons.levelInfo && !state.lessons.levelInfo[userData.chosenLevel])) {
                dispatch(appActions.showLoader(true));

                return httpFetch.fetch(config.services.lessons.urls.classesByLevel, {
                    method: 'POST',
                    params: {
                        centre: centre.info.site_id.replace(/^[0\.]+/, ''),
                        stage: userData.chosenLevel,
                        // @todo 393 availability bug - remove this when we refactor
                        bug393: window.location.pathname
                    },
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    }
                })
                .then(function(response) {
                    var discountPrice = 0,
                        classes = response.length ? response[0] : null,
                        color,
                        iconUrl,
                        subId,
                        sub,
                        comment;

                    if(classes) {
                        color = '#' + classes.color;
                        iconUrl = classes.icon_url;
                        discountPrice = classes.times[0].times[0].discount_prices.month_fee * 100;
                        subId = classes.times[0].times[0].prices.sub_id;
                        sub = centre.filteredLessons && centre.filteredLessons[subId] || null;
                    }

                    if(sub) {
                        comment = centreModel.getCommentsForTag(centre, sub);

                        var levelInfo = {
                            level: userData.chosenLevel,
                            color: color,
                            iconUrl: iconUrl,
                            name: userData.levelName,
                            price: sub ? sub.listPriceInPence : 0,
                            priceDiscount: discountPrice,
                            comment: comment
                        };

                        dispatch(exports.addClasses(classes, levelInfo));

                        dispatch(exports.updateUser({
                            chosenLevel: userData.chosenLevel,
                            levelName: userData.levelName,
                            lessonType: userData.lessonType,
                            ineligible: userData.ineligible
                        }));

                        dispatch(exports.setError());
                        dispatch(appActions.showLoader(false));
                    } else {
                        dispatch(exports.setError('setLevel'));
                        dispatch(appActions.showLoader(false));
                    }
                })
                .catch(function (e) {
                    debug('error: ' + JSON.stringify(e));
                    dispatch(appActions.showLoader(false));
                });
            } else {
                dispatch(exports.updateUser({
                    chosenLevel: userData.chosenLevel,
                    levelName: userData.levelName,
                    lessonType: userData.lessonType,
                    ineligible: userData.ineligible
                }));

                dispatch(appActions.showLoader(false));
            }
        };
    },

    getEligibility: function getEligibility(lessonTypeId, lessonType, age) {
        return function(dispatch) {
            dispatch(exports.updateUser({levels: null}));
            dispatch(exports.setError());
            dispatch(appActions.showLoader(true));

            return httpFetch.fetch(config.services.lessons.urls.eligibility, {
                method: 'POST',
                params: {
                    lessonType: lessonType,
                    age: age,
                    type: lessonTypeId
                },
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            })
            .then(function(response) {
                dispatch(exports.setEligibility(response));

                if(response.level) {
                    var levelObj = {
                        level: response.level
                    };

                    dispatch(exports.fetchCategoryForStage(levelObj, response.static));
                } else {
                    dispatch(appActions.showLoader(false));
                }

                return response;
            })
            .catch(function (e) {
                debug('error: ' + JSON.stringify(e));
            });
        };
    },

    getTypes: function getTypes(response) {
        return {
            type: actionTypes.LESSONS.GET_TYPES,
            typesList: response
        };
    },

    getUnlockedLevels: function getUnlockedLevels(stage) {
        return function(dispatch, getState) {
            var state = getState(),
                centreId = state.centreFinder.selected && state.centreFinder.selected.info.site_id;

            dispatch(appActions.showLoader(true));

            return httpFetch.fetch(config.services.lessons.urls.getUnlocked, {
                method: 'POST',
                params: {
                    centre: centreId.replace(/^[0\.]+/, ''),
                    stage: stage
                },
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            })
            .then(function(response) {
                dispatch(exports.updateUser({
                    unlockedLevels: response
                }));

                dispatch(appActions.showLoader(false));
                return true;
            })
            .catch(function (e) {
                dispatch(appActions.showLoader(false));
                debug('error: ' + JSON.stringify(e));
                return false;
            });
        };
    },

    setCentreTypes: function setCentreTypes(response) {
        return {
            type: actionTypes.LESSONS.SET_CENTRE_TYPES,
            typesList: response
        };
    },

    makeReservation: function makeReservation(activity, session, userId, sessionIndex, level) {
        return function(dispatch, getState) {
            var state = getState(),
                user = state.selections.users.objects[userId];

            dispatch(appActions.showLoader(true));

            return httpFetch.fetch(config.services.lessons.urls.reserveSpace, {
                method: 'POST',
                params: {
                    class: session.mrm_id
                },
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            })
            .then(function(response) {
                // If the server has returned a 200 with an error response, catch it
                if (response.error || !response) {
                    dispatch(exports.setError('lessonSelect', userId, sessionIndex, response.errorCode));
                    dispatch(appActions.showLoader(false));
                    return;
                }

                var lesson = {};
                lesson.selectedLessons = {};
                lesson.selectedLessons[activity.stage_id] = {
                    activity: activity,
                    bookingRef: response,
                    subId: activity.prices.sub_id,
                    discountSubId: activity.discount_prices.sub_id,
                    session: session
                };

                var data = {
                    lesson: lesson
                };

                dispatch(exports.refreshLevel(level));
                dispatch(selectionsActions.changeData(userId, data));
                dispatch(selectionsActions.setLessonForUser(userId, activity.prices.sub_id));

                dispatch(exports.setError());
                dispatch(appActions.showLoader(false));
            })
            .catch(function (e) {
                debug('error: ' + JSON.stringify(e));

                dispatch(exports.setError('lessonSelect', userId, sessionIndex));
                dispatch(appActions.showLoader(false));
            });
        };
    },

    refreshLevel: function refreshLevel(level) {
        return function(dispatch, getState) {
            var state = getState(),
                centreId = state.centreFinder.selected && state.centreFinder.selected.info.site_id;

            if(centreId) {
                dispatch(appActions.showLoader(true));

                return httpFetch.fetch(config.services.lessons.urls.classesByLevel, {
                    method: 'POST',
                    params: {
                        centre: centreId.replace(/^[0\.]+/, ''),
                        stage: level,
                        // @todo 393 availability bug - remove this when we refactor
                        bug393: window.location.pathname
                    },
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    }
                })
                .then(function(response) {
                    var classes = response.length ? response[0] : null,
                        levelInfo = {
                            level: level
                        };

                    dispatch(exports.addClasses(classes, levelInfo));
                    dispatch(appActions.showLoader(false));
                })
                .catch(function (e) {
                    debug('error: ' + JSON.stringify(e));
                    dispatch(appActions.showLoader(false));
                });
            }
        };
    },

    refreshReservation: function refreshReservation(bookingRef, type) {
        return httpFetch.fetch(config.services.lessons.urls.refreshSpace, {
            method: 'POST',
            params: {
                booking: bookingRef,
                paymentType: (type === 'payment' ? true : false)
            },
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
        .catch(function (e) {
            debug('error: ' + JSON.stringify(e));
        });
    },

    refreshTimer: function refreshTimer(type) {
        return function(dispatch, getState) {
            var state = getState(),
                promises = [];

            dispatch(exports.setPaymentTimer(false));

            if(type === 'clear') {
                dispatch(exports.setTimer(true));

                if (timeout) {
                    clearTimeout(timeout);
                }
            } else {
                dispatch(exports.setTimer());

                if(type === 'payment') {
                    dispatch(exports.setPaymentTimer(true));
                }

                if(type === 'refresh' || type === 'payment') {
                    for(var i = 0; i < state.selections.users.objects.length; i++) {
                        var user = state.selections.users.objects[i];

                        if(user.lesson.selectedLessons && Object.keys(user.lesson.selectedLessons).length) {
                            for(var l in user.lesson.selectedLessons) {
                                var lesson = user.lesson.selectedLessons[l];

                                if(!user.guardian && lesson.bookingRef && (user.availableSubscriptions && user.availableSubscriptions.length)) {
                                    promises.push(exports.refreshReservation(lesson.bookingRef, 'payment'));
                                }
                            }
                        }
                    }
                }

                Promise.all(promises)
                    .then(function () {
                        if (timeout) {
                            clearTimeout(timeout);
                        }

                        var timeoutSeconds = config.app.lessonTimeout;
                        if(type === 'payment') {
                            timeoutSeconds = config.app.paymentTimeout;
                        }

                        var now = state.lessons.timeoutStarted ? state.lessons.timeoutTime : Date.now(),
                            timeoutMs = timeoutSeconds * 1000,
                            expiryMs = now + timeoutMs;

                        if (expiryMs - now >= 0) {
                            timeoutMs = expiryMs - now;
                        }

                        timeout = setTimeout( function() {
                            dispatch(appActions.showModal('bookingTimeout'));
                            timeout = null;
                        }, timeoutMs);
                    });
            }
        };
    },

    //release a reservation
    //force this release on the frontend even if the api returns an error
    releaseReservation: function releaseReservation(userId, level) {
        return function(dispatch, getState) {
            var state = getState(),
                user = state.selections.users.objects[userId],
                lesson = user.lesson,
                changedLesson;

            dispatch(appActions.showLoader(true));

            if(!user.lesson.selectedLessons || !user.lesson.selectedLessons[level] || !lesson.selectedLessons[level].bookingRef) {
                return;
            }

            return httpFetch.fetch(config.services.lessons.urls.releaseSpace, {
                method: 'POST',
                params: {
                    booking: lesson.selectedLessons[level].bookingRef
                },
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            })
            .then(function(response) {
                if(state.centreFinder.selected && state.centreFinder.selected.info.site_id) {
                    //set all activity info to null if the lesson exists
                    if(user.lesson.selectedLessons && user.lesson.selectedLessons[level]) {
                        changedLesson = {};
                        changedLesson.selectedLessons = {};
                        changedLesson.selectedLessons[level] = {
                            activity: null,
                            bookingRef: null,
                            subId: null,
                            discountSubId: null,
                            session: null
                        };

                        var data = {
                            lesson: changedLesson
                        };

                        dispatch(selectionsActions.changeData(userId, data));
                    }

                    dispatch(exports.refreshLevel(level));
                }

                dispatch(exports.setError());
                dispatch(appActions.showLoader(false));
            })
            .catch(function (e) {
                if(state.centreFinder.selected && state.centreFinder.selected.info.site_id) {
                    //set all activity info to null if the lesson exists
                    if(user.lesson.selectedLessons && user.lesson.selectedLessons[level]) {
                        changedLesson = {};
                        changedLesson.selectedLessons = {};
                        changedLesson.selectedLessons[level] = {
                            activity: null,
                            bookingRef: null,
                            subId: null,
                            discountSubId: null,
                            session: null
                        };

                        var data = {
                            lesson: changedLesson
                        };

                        dispatch(selectionsActions.changeData(userId, data));
                    }

                    dispatch(exports.refreshLevel(level));
                }

                dispatch(exports.setError());
                dispatch(appActions.showLoader(false));
            });
        };
    },

    removeLevel: function removeLevel(level) {
        return {
            type: actionTypes.LESSONS.UPDATE_SELECTED_LESSONS,
            level: level
        };
    },

    resetEligibility: function resetEligibility(data) {
        return function(dispatch, getState) {
            var state = getState(),
                userData = {},
                users = Object.assign({}, state.selections.users),
                siteId = state.centreFinder.selected.info.site_id


            if (data.chosenLevel) {
                if (!users.count[data.id]) {
                    users.count[data.id] = 1;

                } else if ((users.count[data.id] + 1) <= 20) {
                    users.count[data.id]++;
                }

                //if parent/guardian member exist, remove it when adding a new member
                if(users.total > 0) {
                    var juniorAmount = users.count[siteId + '-JUNIOR'] || users.count['0000-JUNIOR'];

                    if(juniorAmount && juniorAmount !== 0) {
                        var guardianPlace = (users.objects.length - 1) - juniorAmount;

                        if(guardianPlace >= 0) {
                            if(users.objects[guardianPlace].guardian === true) {
                                users.count['freeprofile']--;
                            }
                        }
                    }
                }

                userData.info = data.info;

                userData.lesson = {};
                userData.lesson.selectedLessons = {};

                for(var l in data.selectedLessons) {
                    if(data.selectedLessons[l].chosenLevel) {
                        userData.lesson.selectedLessons[l] = {
                            level: data.selectedLessons[l].chosenLevel,
                            levelName: data.selectedLessons[l].levelName
                        };
                    }
                }
            }

            dispatch(selectionsActions.setUsers(users.count, null, userData));
            dispatch(exports.clearEligibilityUser());
        };
    },

    setAgeError: function setAgeError() {
        return function(dispatch) {
            dispatch(exports.setError('setAgeError'));
        };
    },

    resetUser: function resetUser(data, key, isLoggedIn) {
        return function(dispatch) {
            dispatch(exports.setEligibility(null));
            dispatch(exports.updateUser(data, key, isLoggedIn));
        };
    },

    setEligibility: function setEligibility(response) {
        return {
            type: actionTypes.LESSONS.SET_ELIGIBILITY_OPTIONS,
            eligibilityOptions: response
        };
    },

    setError: function setError(stage, userId, sessionIndex, errorCode) {
        return {
            type: actionTypes.LESSONS.SET_ERROR,
            sessionIndex: sessionIndex,
            stage: stage,
            userId: userId,
            errorCode: errorCode
        };
    },

    setLevelsForUser: function setLevelsForUser(levels, ineligible, levelName) {
        return {
            type: actionTypes.LESSONS.SET_LEVELS_FOR_USER,
            levels: levels,
            levelName: levelName,
            ineligible: ineligible
        };
    },

    setRecommendedLevel: function setRecommendedLevel(data, key, levelObj, isStatic) {
        return function(dispatch) {
            dispatch(exports.updateUser(data, key));
            dispatch(exports.fetchCategoryForStage(levelObj, isStatic));
        };
    },

    setTimetable: function setTimetable(timetable, prices, availability) {
        return {
            type: actionTypes.LESSONS.SET_TIMETABLE,
            availability: availability,
            prices: prices,
            timetable: timetable
        };
    },

    setType: function setType(typeId) {
        typeId = (typeId === '0') ? null : typeId;

        return function(dispatch, getState) {
            var state = getState(),
                lessons = state.lessons,
                lessonType;

            if(lessons.typesList) {
                dispatch(appActions.showLoader(true));

                for(var l in lessons.typesList) {
                    if(lessons.typesList[l]['id'] === parseInt(typeId)) {
                        lessonType = lessons.typesList[l]['name'].toLowerCase().replace(/\s/g, '');
                        break;
                    }
                }

                return httpFetch.fetch(config.services.lessons.urls.relations, {
                    method: 'POST',
                    params: {
                        lessonName: lessonType
                    },
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    }
                })
                .then(function(response) {
                    var isGeneric = (response.isGeneric) ? response.isGeneric : false;
                    var category,
                        isMulti = false;

                    if(response.isMulti && response.category) {
                        category = response.category;
                        isMulti = true;
                    }

                    dispatch(exports.wipeout());
                    dispatch(exports.setTypeId([typeId, lessonType], category, isMulti, isGeneric));
                    dispatch(appActions.showLoader(false));
                })
                .catch(function (e) {
                    debug('error: ' + JSON.stringify(e));
                });
            } else {
                dispatch(exports.wipeout());
                dispatch(exports.setTypeId([typeId, lessonType], null, false, false));
            }
        };
    },

    setTypeId: function setTypeId([typeId, lessonType], category, isMulti, isGeneric) {
        return {
            type: actionTypes.LESSONS.SET_TYPE,
            typeId: typeId,
            lessonType: lessonType,
            category: category,
            isMulti: isMulti,
            isGeneric: isGeneric
        };
    },

    setUserEligibility: function setUserEligibility(data) {
        return {
            type: actionTypes.LESSONS.SET_ELIGIBILITY,
            data: data
        };
    },

    setView: function setView() {
        return {
            type: actionTypes.LESSONS.SET_VIEW
        };
    },

    setTimer: function setTimer(clear) {
        return {
            type: actionTypes.LESSONS.SET_TIMER,
            clear: clear
        };
    },

    setPaymentTimer: function setPaymentTimer(toggle) {
        return {
            type: actionTypes.LESSONS.SET_PAYMENT_TIMER,
            set: toggle
        };
    },

    startTimerOnRefresh: function startTimerOnRefresh() {
        return function(dispatch, getState) {
            var state = getState(),
                startTime = state.lessons.timeoutTime,
                elapsed = Date.now() - startTime,
                timeoutSeconds = state.lessons.paymentTimerActive ? config.app.paymentTimeout : config.app.lessonTimeout,
                expiryMs = (timeoutSeconds * 1000) - elapsed;

            if (timeout) {
                clearTimeout(timeout);
            }

            if (expiryMs < 0) {
                dispatch(appActions.showModal('bookingTimeout'));
            } else {
                timeout = setTimeout( function() {
                    dispatch(appActions.showModal('bookingTimeout'));
                    timeout = null;
                }, expiryMs);
            }
        };
    },

    submitEnquiryToSCV: function submitEnquiryToSCV(params, userId) {
        return function(dispatch) {
            return httpFetch.fetch(config.services.lessons.urls.medicalEnquiry, {
                method: 'POST',
                params: params,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            })
            .then(function(response) {
                dispatch(selectionsActions.changeData(userId, {madeMedicalEnquiry: true }));
            })
            .catch(function (e) {
                debug('error: ' + JSON.stringify(e));
            });
        };
    },

    validateUser: function validateUser(user, lessons, siteId, useYears) {
        var rules = exports.userValidationRules,
            promises = [],
            ruleOptions,
            ageRange,
            value,
            key;

        for (key in rules) {
            ruleOptions = {};

            if (key === 'dob') {
                if (user.id === siteId + '-JUNIOR' || user.id === '0000-JUNIOR') {
                    console.log('age', user.info.age)
                    value = useYears ? user.info.age : userModel.getAgeInMonths(user);

                    console.log('value', value);

                    ruleOptions = { range: ageRange };
                } else {
                    continue;
                }
            } else {
                value = user.info[key] && user.info[key].value;
            }

            promises.push(userModel.validateField(key, value, ruleOptions));
        }

        return Promise.all(promises)
            .then(function(results) {
                var valid = true,
                    errors = {},
                    result,
                    i;

                for (i = 0; i < results.length; i++) {
                    result = results[i];

                    if (!result.valid) {
                        valid = false;
                        errors[result.key] = errors[result.key] || result.validationErrors;

                        break;
                    }

                    if (result.key === 'dob') {
                        valid = !user.invalidForAgeRange;
                    }
                }

                user.valid = valid;

                return {
                    valid: valid,
                    errors: errors,
                };
            });
    },

    updateUser: function updateUser(data, key, isLoggedIn) {
        return {
            type: actionTypes.LESSONS.UPDATE_USER,
            data: data,
            key: key,
            isLoggedIn: isLoggedIn
        };
    },

    wipeout: function wipeout() {
        return {
            type: actionTypes.LESSONS.WIPEOUT
        };
    }
};
