var React = require("react"),
    connect = require("react-redux").connect,
    viewUtils = require("../../utils/viewUtils"),
    utils = require("../../utils"),
    config = require("../../config"),
    CentreModel = require("../../models/centre"),
    UserModel = require("../../models/user"),
    Button = require("../common/button"),
    Input = require("../common/forms/input"),
    SmartDate = require("../common/forms/smartDate"),
    SmartSelect = require("../common/forms/smartSelect"),
    UserLoginForm = require("../common/loginForm"),
    UserSearchForm = require("../common/userSearchForm"),
    FindAddress = require("./findAddress"),
    UserForm;

import Notice from "../common/notices/notice";
import { requiresProofOfEligibility } from "../common/proof-of-eligibility";

UserForm = React.createClass({
    displayName: 'UserForm',

    fields: {},

    render: function render() {
        var contentToggle = this.props.contentToggle,
            contentForm = this.props.contentForm,
            centre = this.props.centre ? this.props.centre : null,
            leadUser = this.props.leadUser,
            useLogin = this.props.useLogin,
            index = this.props.index,
            user = this.props.user,
            addressFields = [],
            titlesOptions = [],
            titlesByDesc = {},
            marketingChannelsInputs = [],
            isLessons =
                this.props.lessons && this.props.lessons.inView ? true : false,
            isBooking = this.props.isBooking ? this.props.isBooking : false,
            basicDetailsFrozen = this.props.frozen,
            contactDetailsFrozen = this.props.frozen,
            emmergencyDetailsFrozen = this.props.frozen,
            medicalConditionsFrozen = this.props.frozen,
            emmergencyDetailsBlock,
            medicalConditionsBlock,
            concessionBlock,
            basicDetailsSection,
            contactDetailsSection,
            addressDetailsSection,
            medicalConditionsSection,
            medicalFollowupSection,
            subscriptionsSection,
            basicDetailsTitle,
            contactDetailsTitle,
            emmergencyDetailsTitle,
            medicalConditionsTitle,
            subscriptionsTitle,
            marketingPrefs,
            marketingChannelsBlock,
            unfreezeButton,
            leadUserHasEmergencyDetails,
            useLeadContactCheckbox,
            leadUserFirstName,
            leadUserLastName,
            facilityDetails,
            permittedSite,
            permittedTime,
            facility,
            staffDisclaimer,
            logoutButton,
            addressPostCode,
            addressLocality,
            addressProperty,
            addressStreet,
            addressRegion,
            leadUserName,
            addressValid,
            useLoginForm,
            addressTown,
            formContent,
            loginForm,
            ageNote,
            descKey,
            toggle,
            title,
            key,
            dob;

        const { validationErrors } = this.props;;

        if (!user.isLoggedInUser && useLogin === true) {
            useLoginForm = true;
        } else {
            useLoginForm = false;
        }

        for (key in this.props.titles) {
            descKey = this.props.titles[key].desc.toLowerCase();

            titlesByDesc[descKey] = titlesByDesc[descKey] || [];
            titlesByDesc[descKey].push(this.props.titles[key]);
        }

        for (key in this.props.titles) {
            descKey = this.props.titles[key].desc.toLowerCase();
            title = this.props.titles[key];

            titlesOptions.push({
                value: title.id,
                text:
                    title.desc +
                    (titlesByDesc[descKey].length > 1
                        ? " (" + title.gender + ")"
                        : "")
            });
        }

        titlesOptions.sort(function(a, b) {
            var titles = UserModel.titlesOrder;

            if (
                (!a.value && !b.value) ||
                (titles.indexOf(a.value) === -1 &&
                    titles.indexOf(b.value) === -1)
            ) {
                return 0;
            }

            if (!a.value || titles.indexOf(a.value) === -1) {
                return 1;
            }

            if (!b.value || titles.indexOf(b.value) === -1) {
                return -1;
            }

            return (
                UserModel.titlesOrder.indexOf(a.value) -
                UserModel.titlesOrder.indexOf(b.value)
            );
        });

        titlesOptions.unshift({ text: contentForm.pleaseSelect, value: null });
        titlesOptions = titlesOptions.filter(
            option => option.value !== "UNKNOWN"
        );

        if (!this.props.showFullForm && !user.isLoggedInUser) {
            // This is bonkers!
            addressValid =
                this.props.validationErrors &&
                this.props.validationErrors.addressProperty &&
                (!user.info ||
                    !user.info.addressProperty ||
                    !user.info.addressProperty.valid)
                    ? false
                    : (!user.info ||
                          !user.info.addressProperty ||
                          user.info.addressProperty.valid) &&
                      this.props.validationErrors &&
                      this.props.validationErrors.addressStreet &&
                      (!user.info ||
                          !user.info.addressStreet ||
                          !user.info.addressStreet.valid)
                    ? false
                    : (!user.info ||
                          !user.info.addressStreet ||
                          user.info.addressStreet.valid) &&
                      this.props.validationErrors &&
                      this.props.validationErrors.addressTown &&
                      (!user.info ||
                          !user.info.addressTown ||
                          !user.info.addressTown.valid)
                    ? false
                    : (!user.info ||
                          !user.info.addressTown ||
                          user.info.addressTown.valid) &&
                      this.props.validationErrors &&
                      this.props.validationErrors.addressPostCode &&
                      (!user.info ||
                          !user.info.addressPostCode ||
                          !user.info.addressPostCode.valid)
                    ? false
                    : (!user.info ||
                          !user.info.addressPostCode ||
                          user.info.addressPostCode.valid) &&
                      this.props.validationErrors &&
                      this.props.validationErrors.addressLocality &&
                      (!user.info ||
                          !user.info.addressLocality ||
                          !user.info.addressLocality.valid)
                    ? false
                    : (!user.info ||
                          !user.info.addressLocality ||
                          user.info.addressLocality.valid) &&
                      this.props.validationErrors &&
                      this.props.validationErrors.addressRegion &&
                      (!user.info ||
                          !user.info.addressRegion ||
                          !user.info.addressRegion.valid)
                    ? false
                    : !user.info ||
                      !user.info.addressRegion ||
                      user.info.addressRegion.valid;
        }

        if (contactDetailsFrozen) {
            addressProperty = (
                (this.props.useLeadAddress
                    ? leadUser.info.addressProperty &&
                      leadUser.info.addressProperty.value
                    : user.info.addressProperty &&
                      user.info.addressProperty.value) || ""
            ).trim();
            addressStreet = (
                (this.props.useLeadAddress
                    ? leadUser.info.addressStreet &&
                      leadUser.info.addressStreet.value
                    : user.info.addressStreet &&
                      user.info.addressStreet.value) || ""
            ).trim();
            addressTown = (
                (this.props.useLeadAddress
                    ? leadUser.info.addressTown &&
                      leadUser.info.addressTown.value
                    : user.info.addressTown && user.info.addressTown.value) ||
                ""
            ).trim();
            addressPostCode = (
                (this.props.useLeadAddress
                    ? leadUser.info.addressPostCode &&
                      leadUser.info.addressPostCode.value
                    : user.info.addressPostCode &&
                      user.info.addressPostCode.value) || ""
            ).trim();
            addressLocality = (
                (this.props.useLeadAddress
                    ? leadUser.info.addressLocality &&
                      leadUser.info.addressLocality.value
                    : user.info.addressLocality &&
                      user.info.addressLocality.value) || ""
            ).trim();
            addressRegion = (
                (this.props.useLeadAddress
                    ? leadUser.info.addressRegion &&
                      leadUser.info.addressRegion.value
                    : user.info.addressRegion &&
                      user.info.addressRegion.value) || ""
            ).trim();

            addressProperty && addressFields.push(addressProperty);
            addressStreet && addressFields.push(addressStreet);
            addressTown && addressFields.push(addressTown);
            addressPostCode && addressFields.push(addressPostCode);
            addressLocality && addressFields.push(addressLocality);
            addressRegion && addressFields.push(addressRegion);
        }

        basicDetailsTitle = (
            <h3 className="personal-details__section-title">
                {contentForm.basicDetails}
            </h3>
        );
        contactDetailsTitle = (
            <h3 className="personal-details__section-title">
                {contentForm.contactDetails}
            </h3>
        );
        emmergencyDetailsTitle = (
            <h3 className="personal-details__section-title">
                {contentForm.emmergencyDetails}
            </h3>
        );
        medicalConditionsTitle = (
            <h3 className="personal-details__section-title">
                {contentForm.medicalConditions}
            </h3>
        );
        subscriptionsTitle = (
            <h3 className="personal-details__section-title">
                {contentForm.stayUpToDate}
            </h3>
        );

        if (this.props.frozen && !this.props.roUser) {
            unfreezeButton = (
                <Button
                    className="button button--change personal-details__user-change-button"
                    onClick={this.props.onUnfreeze}
                >
                    {contentForm.buttonChange}
                </Button>
            );
        }

        if (leadUser) {
            leadUserFirstName =
                leadUser.info.firstName && leadUser.info.firstName.value;
            leadUserLastName =
                leadUser.info.lastName && leadUser.info.lastName.value;

            if (leadUserFirstName && leadUserLastName) {
                leadUserName = leadUserFirstName + " " + leadUserLastName;
            } else {
                leadUserName =
                    leadUser.typeDesc +
                    " " +
                    (leadUser.idInType + 1) +
                    (leadUserFirstName && leadUserLastName
                        ? ": " + leadUserFirstName + " " + leadUserLastName
                        : "");
            }
        }

        //get description of user facilities
        if (
            isLessons &&
            user.facilities &&
            Object.keys(user.facilities).length
        ) {
            for (var f in user.facilities) {
                if (user.facilities[f].selected === "on") {
                    permittedSite = this.props.centre.permittedSites[
                        user.facilities[f].permittedSites
                    ].desc;
                    permittedTime = this.props.centre.permittedTimes[
                        user.facilities[f].permittedTimes
                    ].desc;

                    for (var c = 0; this.props.centre.facilities.length; c++) {
                        if (this.props.centre.facilities[c].id === f) {
                            facility = this.props.centre.facilities[c].desc;
                            break;
                        }
                    }

                    break;
                }
            }

            facilityDetails = (
                <div className="personal-details__facilities">
                    {facility && <span>{facility + ","} </span>}
                    {facility && <span>{permittedSite + ","} </span>}
                    {facility && <span>{permittedTime}</span>}
                </div>
            );
        }

        if (isLessons && user.lesson && user.lesson.selectedLessons) {
            facilityDetails = Object.values(user.lesson.selectedLessons)
                .map(x => {
                    return x.levelName;
                })
                .map(lessonDesc => {
                    return (
                        <div className="personal-details__facilities">
                            {facility && <span>{lessonDesc} </span>}
                        </div>
                    );
                });
        }

        if (!this.props.frozen && !user.isLoggedInUser) {
            toggle = (
                <div className="personal-details-toggle">
                    <div className="personal-details-toggle__switch">
                        {this.props.doesRequireProofOfEligibility(user) &&
                            this.props.concessionMessage && (
                                <Notice
                                    message={this.props.concessionMessage}
                                />
                            )}

                        <p
                            className="personal-details-toggle__text"
                            dangerouslySetInnerHTML={{
                                __html: contentToggle.doesUserHaveAnAccount.replace(
                                    "{name}",
                                    this.props.userFirstName ||
                                        this.props.userName
                                )
                            }}
                        ></p>
                        <div className="toggle__buttons">
                            <button
                                className={
                                    "toggle__option toggle__option--one" +
                                    (!useLoginForm
                                        ? " toggle__option--active"
                                        : "")
                                }
                                onClick={this.props.onUseLoginForm.bind(
                                    null,
                                    this.props.index,
                                    true
                                )}
                            >
                                {contentToggle.yes}
                            </button>

                            <span
                                className="toggle__label"
                                onClick={
                                    !useLoginForm
                                        ? this.props.onUseLoginForm.bind(
                                              null,
                                              this.props.index,
                                              true
                                          )
                                        : this.props.onUseLoginForm.bind(
                                              null,
                                              this.props.index,
                                              false
                                          )
                                }
                            ></span>

                            <button
                                className={
                                    "toggle__option toggle__option--two" +
                                    (useLoginForm
                                        ? " toggle__option--active"
                                        : "")
                                }
                                onClick={this.props.onUseLoginForm.bind(
                                    null,
                                    this.props.index,
                                    false
                                )}
                            >
                                {contentToggle.no}
                            </button>

                            <div
                                className={
                                    "toggle__slider" +
                                    (useLoginForm
                                        ? " toggle__slider--one"
                                        : " toggle__slider--two")
                                }
                            ></div>
                        </div>
                    </div>
                    {!this.props.isStaff && useLoginForm ? (
                        <p className="personal-details-toggle__call-text">
                            {this.props.contentLoginForm.loginToGetDetails.replace(
                                "{name}",
                                this.props.userFirstName || this.props.userName
                            )}
                        </p>
                    ) : null}
                </div>
            );

            if (useLoginForm && user) {
                if (this.props.isStaff) {
                    loginForm = (
                        <UserSearchForm
                            isGuardian={user.guardian ? user.guardian : false}
                            uid={this.props.index}
                            userName={
                                this.props.userFirstName || this.props.userName
                            }
                            userType={user.type}
                        />
                    );
                } else {
                    loginForm = (
                        <UserLoginForm
                            uid={this.props.index}
                            user={user}
                            userName={
                                this.props.userFirstName || this.props.userName
                            }
                            content={this.props.contentLoginForm}
                        />
                    );
                }
            }
        }

        if (!basicDetailsFrozen) {
            const currentYear = new Date().getFullYear();
            const dateParts =
                user.info.dob && utils.getDateParts(user.info.dob.value);
            const dobIsValid =
                dateParts &&
                dateParts.year >= 1900 &&
                dateParts.year <= currentYear;

            basicDetailsSection = (
                <div>
                    <label className="personal-details__label disable-speak">
                        {contentForm.yourName}
                    </label>
                    <Input
                        className="personal-details__input"
                        input={{
                            onChange: this.props.onChangeInput.bind(
                                null,
                                "title"
                            ),
                            required: true
                        }}
                        label={{
                            className: "a11y",
                            text: contentForm.fields.title.label
                        }}
                        name={"details_title_" + index}
                        type="select"
                        options={titlesOptions}
                        valid={
                            this.props.validationErrors &&
                            this.props.validationErrors.title &&
                            (!user.info ||
                                !user.info.title ||
                                !user.info.title.valid)
                                ? false
                                : !user.info ||
                                  !user.info.title ||
                                  user.info.title.valid
                        }
                        value={user.info.title && user.info.title.value}
                    />

                    <Input
                        className="personal-details__input"
                        input={{
                            onChange: this.props.onChangeInput.bind(
                                null,
                                "firstName"
                            ),
                            placeholder:
                                contentForm.fields.firstName.placeholder,
                            required: true
                        }}
                        label={{
                            className: "a11y",
                            text: contentForm.fields.firstName.label
                        }}
                        name={"details_first_name_" + index}
                        type="text"
                        valid={
                            this.props.validationErrors &&
                            this.props.validationErrors.firstName &&
                            (!user.info ||
                                !user.info.firstName ||
                                !user.info.firstName.valid)
                                ? false
                                : !user.info ||
                                  !user.info.firstName ||
                                  user.info.firstName.valid
                        }
                        value={
                            (user.info.firstName &&
                                user.info.firstName.value) ||
                            ""
                        }
                    />

                    <Input
                        className="personal-details__input"
                        input={{
                            onChange: this.props.onChangeInput.bind(
                                null,
                                "lastName"
                            ),
                            placeholder:
                                contentForm.fields.lastName.placeholder,
                            required: true
                        }}
                        label={{
                            className: "a11y",
                            text: contentForm.fields.lastName.label
                        }}
                        name={"details_last_name_" + index}
                        type="text"
                        valid={
                            this.props.validationErrors &&
                            this.props.validationErrors.lastName &&
                            (!user.info ||
                                !user.info.lastName ||
                                !user.info.lastName.valid)
                                ? false
                                : !user.info ||
                                  !user.info.lastName ||
                                  user.info.lastName.valid
                        }
                        value={
                            (user.info.lastName && user.info.lastName.value) ||
                            ""
                        }
                    />

                    {ageNote}

                    <SmartDate
                        className="personal-details__input--date"
                        disabled={user.isLoggedInUser && !user.fallbackAge}
                        input={{
                            onChange: this.props.onChangeInput.bind(
                                null,
                                "dob"
                            ),
                            placeholder: contentForm.fields.dob.placeholder,
                            required: true
                        }}
                        label={{
                            text: contentForm.fields.dob.label
                        }}
                        name={"details_dob_" + index}
                        valid={
                            dobIsValid &&
                            this.props.validationErrors &&
                            this.props.validationErrors.dob &&
                            (!user.info ||
                                !user.info.dob ||
                                !user.info.dob.valid)
                                ? false
                                : !user.info ||
                                  !user.info.dob ||
                                  user.info.dob.valid
                        }
                        value={user.info.dob && user.info.dob.value}
                        invalidAgeRange={user.invalidForAgeRange}
                        extras={user.extras || null}
                        age={user.info.age || 0}
                    />

                    {centre.ethnicity_ask && (
                        <Input
                            className="personal-details__input"
                            input={{
                                onChange: this.props.onChangeInput.bind(
                                    null,
                                    "ethnicity"
                                ),
                                required: centre.ethnicity_require
                            }}
                            label={{
                                text: contentForm.fields.ethnicity.label
                            }}
                            name={"details_ethnicity_" + index}
                            type="select"
                            options={(() => {
                                const ethnicitiesList = (
                                    (user.info.ethnicityOptions || {}).value ||
                                    []
                                ).length
                                    ? user.info.ethnicityOptions.value
                                    : centre.ethnicities;
                                const ethnicityInitialValue =
                                    ((user.info || {}).ethnicity_initial || {}).value ||
                                    "";
                                const list = ethnicitiesList.map(value => ({
                                    text: value,
                                    value
                                }));
                                if (!ethnicityInitialValue) {
                                    list.unshift({
                                        text: "Please select",
                                        value: ""
                                    });
                                }
                                return list;
                            })()}
                            valid={
                                (user.info.ethnicity || {}).valid !== false &&
                                !Object.keys(validationErrors.ethnicity || {}).length
                            }
                            value={
                                ((user.info || {}).ethnicity || {}).value || ""
                            }
                        />
                    )}
                </div>
            );
        } else {
            if (user.info.dob && user.info.dob.value) {
                dob = viewUtils.formatDate(user.info.dob.value);
            }

            var ageText =
                user.info.age && user.info.age !== 0
                    ? "(Age: " + user.info.age + ")"
                    : "";

            basicDetailsSection = (
                <div>
                    <span className="personal-details__user-label">
                        {contentForm.name}
                    </span>
                    <span className="personal-details__user-value">
                        {(user.info.title && user.info.title.value) ||
                        (user.info.firstName && user.info.firstName.value) ||
                        (user.info.lastName && user.info.lastName.value)
                            ? (user.info.firstName &&
                                  user.info.firstName.value) +
                              " " +
                              (user.info.lastName && user.info.lastName.value)
                            : "\u00a0"}
                    </span>

                    <span className="personal-details__user-label">
                        {contentForm.dob}
                    </span>
                    <span className="personal-details__user-value">
                        {dob || "\u00a0"} {ageText}
                    </span>

                    {centre.ethnicity_ask && (user.info.ethnicity || {}).value && (
                        <div>
                            <span className="personal-details__user-label">
                                {contentForm.ethnicity}
                            </span>
                            <span className="personal-details__user-value">
                                {user.info.ethnicity.value}
                            </span>
                        </div>
                    )}
                </div>
            );
        }

        if (!contactDetailsFrozen) {
            if (!user.lead && leadUser) {
                useLeadContactCheckbox = (
                    <div className="input personal-details__input--cb">
                        <input
                            className="forms__input--checkbox"
                            key={"ulc" + index}
                            onChange={this.props.onUseLeadContact.bind(
                                null,
                                index
                            )}
                            type="checkbox"
                            name={"use_lead_contact_" + index}
                            id={"use_lead_contact_" + index}
                            checked={this.props.useLeadContact}
                        />
                        <label htmlFor={"use_lead_contact_" + index}>
                            <span></span>
                            {contentForm.useLeadContactDetails.replace(
                                "{userName}",
                                leadUserName
                            )}
                        </label>
                    </div>
                );
            }

            if (this.props.showFullForm || user.isLoggedInUser) {
                addressDetailsSection = (
                    <div>
                        <Input
                            key="p_d_addressProperty"
                            className="personal-details__input"
                            input={{
                                onChange: this.props.onChangeInput.bind(
                                    null,
                                    "addressProperty"
                                ),
                                placeholder:
                                    contentForm.fields.property.placeholder
                            }}
                            label={{
                                text: contentForm.fields.property.label
                            }}
                            name={"details_address_property_" + index}
                            type="text"
                            valid={
                                this.props.validationErrors &&
                                this.props.validationErrors.addressProperty &&
                                (!user.info ||
                                    !user.info.addressProperty ||
                                    !user.info.addressProperty.valid)
                                    ? false
                                    : !user.info ||
                                      !user.info.addressProperty ||
                                      user.info.addressProperty.valid
                            }
                            value={
                                (user.info.addressProperty &&
                                    user.info.addressProperty.value) ||
                                ""
                            }
                        />

                        <Input
                            key="p_d_addressStreet"
                            className="personal-details__input"
                            input={{
                                onChange: this.props.onChangeInput.bind(
                                    null,
                                    "addressStreet"
                                ),
                                placeholder:
                                    contentForm.fields.street.placeholder,
                                required: true
                            }}
                            label={{
                                text: contentForm.fields.street.label
                            }}
                            name={"details_address_street_" + index}
                            type="text"
                            valid={
                                this.props.validationErrors &&
                                this.props.validationErrors.addressStreet &&
                                (!user.info ||
                                    !user.info.addressStreet ||
                                    !user.info.addressStreet.valid)
                                    ? false
                                    : !user.info ||
                                      !user.info.addressStreet ||
                                      user.info.addressStreet.valid
                            }
                            value={
                                (user.info.addressStreet &&
                                    user.info.addressStreet.value) ||
                                ""
                            }
                        />

                        <Input
                            key="p_d_addressLocality"
                            className="personal-details__input"
                            input={{
                                onChange: this.props.onChangeInput.bind(
                                    null,
                                    "addressLocality"
                                ),
                                placeholder:
                                    contentForm.fields.locality.placeholder
                            }}
                            label={{
                                text: contentForm.fields.locality.label
                            }}
                            name={"details_address_locality_" + index}
                            type="text"
                            valid={
                                this.props.validationErrors &&
                                this.props.validationErrors.addressLocality &&
                                (!user.info ||
                                    !user.info.addressLocality ||
                                    !user.info.addressLocality.valid)
                                    ? false
                                    : !user.info ||
                                      !user.info.addressLocality ||
                                      user.info.addressLocality.valid
                            }
                            value={
                                (user.info.addressLocality &&
                                    user.info.addressLocality.value) ||
                                ""
                            }
                        />

                        <Input
                            key="p_d_addressTown"
                            className="personal-details__input"
                            input={{
                                onChange: this.props.onChangeInput.bind(
                                    null,
                                    "addressTown"
                                ),
                                placeholder:
                                    contentForm.fields.town.placeholder,
                                required: true
                            }}
                            label={{
                                text: contentForm.fields.town.label
                            }}
                            name={"details_address_town_" + index}
                            type="text"
                            valid={
                                this.props.validationErrors &&
                                this.props.validationErrors.addressTown &&
                                (!user.info ||
                                    !user.info.addressTown ||
                                    !user.info.addressTown.valid)
                                    ? false
                                    : !user.info ||
                                      !user.info.addressTown ||
                                      user.info.addressTown.valid
                            }
                            value={
                                (user.info.addressTown &&
                                    user.info.addressTown.value) ||
                                ""
                            }
                        />

                        <Input
                            key="p_d_addressRegion"
                            className="personal-details__input"
                            input={{
                                onChange: this.props.onChangeInput.bind(
                                    null,
                                    "addressRegion"
                                ),
                                placeholder:
                                    contentForm.fields.region.placeholder
                            }}
                            label={{
                                text: contentForm.fields.region.label
                            }}
                            name={"details_address_region_" + index}
                            type="text"
                            valid={
                                this.props.validationErrors &&
                                this.props.validationErrors.addressRegion &&
                                (!user.info ||
                                    !user.info.addressRegion ||
                                    !user.info.addressRegion.valid)
                                    ? false
                                    : !user.info ||
                                      !user.info.addressRegion ||
                                      user.info.addressRegion.valid
                            }
                            value={
                                (user.info.addressRegion &&
                                    user.info.addressRegion.value) ||
                                ""
                            }
                        />

                        <Input
                            key="p_d_addressPostCode"
                            className="personal-details__input personal-details__input--postcode"
                            input={{
                                onChange: this.props.onChangeInput.bind(
                                    null,
                                    "addressPostCode"
                                ),
                                placeholder:
                                    contentForm.fields.postCode.placeholder,
                                required: true
                            }}
                            label={{
                                text: contentForm.fields.postCode.label
                            }}
                            name={"details_address_postcode_" + index}
                            type="postcode"
                            valid={
                                this.props.validationErrors &&
                                this.props.validationErrors.addressPostCode &&
                                (!user.info ||
                                    !user.info.addressPostCode ||
                                    !user.info.addressPostCode.valid)
                                    ? false
                                    : !user.info ||
                                      !user.info.addressPostCode ||
                                      user.info.addressPostCode.valid
                            }
                            value={
                                (user.info.addressPostCode &&
                                    user.info.addressPostCode.value) ||
                                ""
                            }
                            validityMessage={
                                user.info.addressPostCode &&
                                user.info.addressPostCode.validationErrors &&
                                user.info.addressPostCode.validationErrors
                                    .isFsgPostCode
                                    ? "Southwark postcode required"
                                    : null
                            }
                        />
                    </div>
                );
            } else {
                addressDetailsSection = (
                    <div
                        className={
                            "input personal-details__input personal-details__input--address-finder" +
                            (addressValid ? "" : " input--hints input--invalid")
                        }
                    >
                        <label htmlFor={"details_address_street_" + index}>
                            {contentForm.fields.addressSearch.label}
                        </label>
                        <div className="input">
                            <FindAddress
                                addressValid={addressValid}
                                id={"details_address_street_" + index}
                                uid={index}
                                suggestions={this.props.addressSuggestions}
                            />
                            {this.props.addressValid ? null : (
                                <span className="form__input-validity-icon"></span>
                            )}
                        </div>
                        <div className="personal-details__manual-entry">
                            {contentForm.cantFindYourAddress}{" "}
                            <Button
                                className="personal-details__manual-entry-button"
                                onClick={this.props.onUseManualEntry}
                            >
                                {contentForm.buttonManualEntry}
                            </Button>
                        </div>
                    </div>
                );
            }

            contactDetailsSection = (
                <div>
                    {useLeadContactCheckbox}

                    {user.lead || !this.props.useLeadContact || !leadUser ? (
                        <div>
                            <Input
                                className="personal-details__input"
                                input={{
                                    onBlur: this.props.onBlurInput.bind(
                                        null,
                                        "email"
                                    ),
                                    placeholder: contentForm.fields.email.placeholder.replace(
                                        "{name}",
                                        this.props.userFirstName || "sarah"
                                    ),
                                    required: true
                                }}
                                label={{
                                    text: contentForm.fields.email.label
                                }}
                                name={"details_email_" + index}
                                type="email"
                                valid={
                                    this.props.validationErrors &&
                                    this.props.validationErrors.email &&
                                    (!user.info ||
                                        !user.info.email ||
                                        !user.info.email.valid)
                                        ? false
                                        : !user.info ||
                                          !user.info.email ||
                                          user.info.email.valid
                                }
                                value={
                                    (user.info.email &&
                                        user.info.email.value) ||
                                    ""
                                }
                            />
                            <Input
                                className="personal-details__input"
                                input={{
                                    onBlur: this.props.onBlurInput.bind(
                                        null,
                                        "telephone"
                                    ),
                                    placeholder:
                                        contentForm.fields.phone.placeholder,
                                    required: true
                                }}
                                label={{
                                    text: contentForm.fields.phone.label
                                }}
                                name={"details_telephone_" + index}
                                type="tel"
                                valid={
                                    this.props.validationErrors &&
                                    this.props.validationErrors.telephone &&
                                    (!user.info ||
                                        !user.info.telephone ||
                                        !user.info.telephone.valid)
                                        ? false
                                        : !user.info ||
                                          !user.info.telephone ||
                                          user.info.telephone.valid
                                }
                                value={
                                    (user.info.telephone &&
                                        user.info.telephone.value) ||
                                    ""
                                }
                            />

                            {addressDetailsSection}
                        </div>
                    ) : null}
                </div>
            );
        } else {
            contactDetailsSection = (
                <div>
                    <span className="personal-details__user-label">
                        {contentForm.email}
                    </span>
                    <span className="personal-details__user-value">
                        {(user.info.email && user.info.email.value) || "\u00a0"}
                    </span>

                    <span className="personal-details__user-label">
                        {contentForm.telephone}
                    </span>
                    <span className="personal-details__user-value">
                        {(user.info.telephone && user.info.telephone.value) ||
                            "\u00a0"}
                    </span>

                    <span className="personal-details__user-label">
                        {contentForm.address}
                    </span>
                    <span
                        className="personal-details__user-value"
                        dangerouslySetInnerHTML={{
                            __html: addressFields.join("<br/>")
                        }}
                    ></span>
                </div>
            );
        }

        if (!(isBooking && user.type === "freeprofile")) {
            if (!emmergencyDetailsFrozen) {
                if (!user.lead && leadUser) {
                    if (
                        leadUser.info.emmergencyName &&
                        leadUser.info.emmergencyName.value &&
                        leadUser.info.emmergencyPhone &&
                        leadUser.info.emmergencyPhone.value
                    ) {
                        leadUserHasEmergencyDetails = true;
                    } else {
                        leadUserHasEmergencyDetails = false;
                    }
                }

                if (
                    user.lead ||
                    !this.props.useLeadEmmergencyDetails ||
                    !leadUserHasEmergencyDetails ||
                    !leadUser
                ) {
                    emmergencyDetailsBlock = (
                        <div
                            className={
                                "module module--inactive" +
                                (emmergencyDetailsFrozen
                                    ? " personal-details__user-section--frozen"
                                    : "")
                            }
                        >
                            <div className="personal-details__container">
                                {emmergencyDetailsTitle}

                                <Input
                                    className="personal-details__input"
                                    input={{
                                        onChange: this.props.onChangeInput.bind(
                                            null,
                                            "emmergencyName"
                                        ),
                                        placeholder:
                                            contentForm.fields.emmergencyName
                                                .placeholder,
                                        required:
                                            user.typeDesc.toLowerCase() ===
                                            "junior"
                                                ? true
                                                : false
                                    }}
                                    label={{
                                        text:
                                            contentForm.fields.emmergencyName
                                                .label
                                    }}
                                    name={"details_emergency_name_" + index}
                                    type="text"
                                    valid={
                                        this.props.validationErrors &&
                                        this.props.validationErrors
                                            .emmergencyName &&
                                        (!user.info ||
                                            !user.info.emmergencyName ||
                                            !user.info.emmergencyName.valid)
                                            ? false
                                            : !user.info ||
                                              !user.info.emmergencyName ||
                                              user.info.emmergencyName.valid
                                    }
                                    value={
                                        (user.info.emmergencyName &&
                                            user.info.emmergencyName.value) ||
                                        ""
                                    }
                                />

                                <Input
                                    className="personal-details__input"
                                    input={{
                                        onBlur: this.props.onBlurInput.bind(
                                            null,
                                            "emmergencyPhone"
                                        ),
                                        placeholder:
                                            contentForm.fields.emmergencyPhone
                                                .placeholder,
                                        required:
                                            user.typeDesc.toLowerCase() ===
                                            "junior"
                                                ? true
                                                : false
                                    }}
                                    label={{
                                        text:
                                            contentForm.fields.emmergencyPhone
                                                .label
                                    }}
                                    name={"details_emergency_phone_" + index}
                                    type="tel"
                                    valid={
                                        this.props.validationErrors &&
                                        this.props.validationErrors
                                            .emmergencyPhone &&
                                        (!user.info ||
                                            !user.info.emmergencyPhone ||
                                            !user.info.emmergencyPhone.valid)
                                            ? false
                                            : !user.info ||
                                              !user.info.emmergencyPhone ||
                                              user.info.emmergencyPhone.valid
                                    }
                                    value={
                                        (user.info.emmergencyPhone &&
                                            user.info.emmergencyPhone.value) ||
                                        ""
                                    }
                                />
                            </div>
                        </div>
                    );
                }
            } else {
                var emmergencyName =
                        (this.props.useLeadEmmergencyDetails
                            ? leadUser.info.emmergencyName &&
                              leadUser.info.emmergencyName.value
                            : user.info.emmergencyName &&
                              user.info.emmergencyName.value) || "\u00a0",
                    emmergencyPhone =
                        (this.props.useLeadEmmergencyDetails
                            ? leadUser.info.emmergencyPhone &&
                              leadUser.info.emmergencyPhone.value
                            : user.info.emmergencyPhone &&
                              user.info.emmergencyPhone.value) || "\u00a0";

                if (
                    emmergencyName !== "\u00a0" ||
                    emmergencyPhone !== "\u00a0"
                ) {
                    emmergencyDetailsBlock = (
                        <div
                            className={
                                "module module--inactive" +
                                (emmergencyDetailsFrozen
                                    ? " personal-details__user-section--frozen"
                                    : "")
                            }
                        >
                            <div className="personal-details__container">
                                {emmergencyDetailsTitle}
                                <span className="personal-details__user-label">
                                    {contentForm.name}
                                </span>
                                <span className="personal-details__user-value">
                                    {emmergencyName}
                                </span>

                                <span className="personal-details__user-label">
                                    {contentForm.telephone}
                                </span>
                                <span className="personal-details__user-value">
                                    {emmergencyPhone}
                                </span>
                            </div>
                        </div>
                    );
                }
            }
        }

        if (
            isLessons &&
            this.props.medicalConditions &&
            Object.keys(user.lesson || {}).length > 0
        ) {
            if (!medicalConditionsFrozen) {
                medicalConditionsSection = (
                    <div>
                        <SmartSelect
                            className="personal-details__input personal-details__input--medical"
                            input={{
                                onChange: this.props.onChangeInput.bind(
                                    null,
                                    "medicalConditions"
                                ),
                                placeholder:
                                    contentForm.fields.medicalConditions
                                        .placeholder,
                                required: false
                            }}
                            label={{
                                text: contentForm.fields.medicalConditions.label
                            }}
                            hasMax={2}
                            name={"medical_conditions_name_" + index}
                            suggestions={this.props.medicalConditions}
                            type="text"
                            value={
                                (user.info.medicalConditions &&
                                    user.info.medicalConditions.value) ||
                                ""
                            }
                        />
                    </div>
                );

                if (user.type.includes("-JUNIOR")) {
                    medicalFollowupSection = (
                        <div>
                            <Input
                                className="personal-details__input--cb"
                                input={{
                                    onChange: this.props.onChangeInput.bind(
                                        null,
                                        "medicalFollowup"
                                    ),
                                    className: "forms__input--checkbox",
                                    required: false
                                }}
                                label={{
                                    text:
                                        contentForm.fields.medicalConditions
                                            .followup
                                }}
                                inputFirst={true}
                                name={"medical_followup_" + index}
                                type="checkbox"
                                valid={
                                    this.props.validationErrors &&
                                    this.props.validationErrors
                                        .medicalFollowup &&
                                    (!user.info ||
                                        !user.info.medicalFollowup ||
                                        !user.info.medicalFollowup.valid)
                                        ? false
                                        : !user.info ||
                                          !user.info.medicalFollowup ||
                                          user.info.medicalFollowup.valid
                                }
                                value={
                                    user.info.medicalFollowup &&
                                    user.info.medicalFollowup.value
                                }
                            />
                        </div>
                    );
                }
            } else {
                var conditions =
                    user.info.medicalConditions &&
                    user.info.medicalConditions.value;
                conditions = conditions ? conditions.join(", ") : null;

                medicalConditionsSection = (
                    <div>
                        <span className="personal-details__user-label">
                            {contentForm.conditions}
                        </span>
                        <span className="personal-details__user-value">
                            {conditions || "\u00a0"}
                        </span>
                    </div>
                );
            }

            medicalConditionsBlock = (
                <div
                    className={
                        "module module--inactive" +
                        (medicalConditionsFrozen
                            ? " personal-details__user-section--frozen"
                            : "")
                    }
                >
                    <div className="personal-details__container">
                        {medicalConditionsTitle}
                        {medicalConditionsSection}
                        {medicalFollowupSection}
                    </div>
                </div>
            );
        }

        if (user.typeDesc !== "Junior") {
            var marketingPrefs = (
                <div>
                    <li>
                        <Input
                            className="personal-details__input--radio"
                            input={{
                                onChange: this.props.onChangeInput.bind(
                                    null,
                                    "marketingGuest"
                                ),
                                className: "forms__input--radio"
                            }}
                            label={{
                                text: contentForm.fields.marketingGuest.label
                            }}
                            options={[
                                {
                                    value: "true",
                                    text: "Yes"
                                },
                                {
                                    value: "false",
                                    text: "No"
                                }
                            ]}
                            name={"radio_guest_" + index}
                            radioLabelClassName={
                                "personal-details__radio-label"
                            }
                            type="radio"
                            valid={
                                this.props.validationErrors &&
                                this.props.validationErrors.marketingGuest &&
                                (!user.info ||
                                    !user.info.marketingGuest ||
                                    !user.info.marketingGuest.valid)
                                    ? false
                                    : !user.info ||
                                      !user.info.marketingGuest ||
                                      user.info.marketingGuest.valid
                            }
                            value={
                                user.info.marketingGuest &&
                                user.info.marketingGuest.value
                            }
                        />
                    </li>
                    <li>
                        <Input
                            className="personal-details__input--radio"
                            input={{
                                onChange: this.props.onChangeInput.bind(
                                    null,
                                    "marketingPartners"
                                ),
                                className: "forms__input--radio"
                            }}
                            label={{
                                text: contentForm.fields.marketingPartners.label
                            }}
                            options={[
                                {
                                    value: "true",
                                    text: "Yes"
                                },
                                {
                                    value: "false",
                                    text: "No"
                                }
                            ]}
                            name={"radio_partners_" + index}
                            radioLabelClassName={
                                "personal-details__radio-label"
                            }
                            type="radio"
                            valid={
                                this.props.validationErrors &&
                                this.props.validationErrors.marketingPartners &&
                                (!user.info ||
                                    !user.info.marketingPartners ||
                                    !user.info.marketingPartners.valid)
                                    ? false
                                    : !user.info ||
                                      !user.info.marketingPartners ||
                                      user.info.marketingPartners.valid
                            }
                            value={
                                user.info.marketingPartners &&
                                user.info.marketingPartners.value
                            }
                        />
                    </li>
                    <li>
                        <Input
                            className="personal-details__input--radio"
                            input={{
                                onChange: this.props.onChangeInput.bind(
                                    null,
                                    "marketingCouncil"
                                ),
                                className: "forms__input--radio"
                            }}
                            label={{
                                text: contentForm.fields.marketingCouncil.label
                            }}
                            options={[
                                {
                                    value: "true",
                                    text: "Yes"
                                },
                                {
                                    value: "false",
                                    text: "No"
                                }
                            ]}
                            name={"radio_council_" + index}
                            radioLabelClassName={
                                "personal-details__radio-label"
                            }
                            type="radio"
                            valid={
                                this.props.validationErrors &&
                                this.props.validationErrors.marketingCouncil &&
                                (!user.info ||
                                    !user.info.marketingCouncil ||
                                    !user.info.marketingCouncil.valid)
                                    ? false
                                    : !user.info ||
                                      !user.info.marketingCouncil ||
                                      user.info.marketingCouncil.valid
                            }
                            value={
                                user.info.marketingCouncil &&
                                user.info.marketingCouncil.value
                            }
                        />
                    </li>
                </div>
            );

            for (var channel in contentForm.fields.marketingChannels) {
                marketingChannelsInputs.push(
                    <div key={"channel_" + channel}>
                        <Input
                            className="personal-details__input--cb"
                            input={{
                                onChange: this.props.onChangeCheckboxInput.bind(
                                    null,
                                    "marketingChannels",
                                    channel
                                ),
                                className: "forms__input--checkbox",
                                required: false
                            }}
                            label={{
                                text:
                                    contentForm.fields.marketingChannels[
                                        channel
                                    ]
                            }}
                            inputFirst={true}
                            name={"marketing_channels" + channel + "_" + index}
                            type="checkbox"
                            valid={
                                !this.props.validationErrors.marketingChannels
                            }
                            value={
                                user.info.marketingChannels &&
                                user.info.marketingChannels.value &&
                                user.info.marketingChannels.value[channel]
                            }
                        />
                    </div>
                );
            }

            marketingChannelsBlock = (
                <div
                    className={
                        "personal-details__channels " +
                        (this.props.showMarketingChannels
                            ? "personal-details__channels--visibile"
                            : "")
                    }
                >
                    <label className="personal-details__label disable-speak">
                        {contentForm.marketingChannelsLabel}
                    </label>
                    {marketingChannelsInputs}

                    {/* If validation failed */}
                    {this.props.validationErrors.marketingChannels &&
                        !this.props.validationErrors.marketingChannels
                            .valid && (
                            <div className="alert alert-warning">
                                <p>At least one must be selected to continue</p>
                            </div>
                        )}
                </div>
            );
        }

        subscriptionsSection = (
            <div>
                <ul className="personal-details__options-list">
                    {marketingPrefs}
                    {marketingChannelsBlock}

                    <li>
                        <Input
                            className="personal-details__input--radio"
                            input={{
                                onChange: this.props.onChangeInput.bind(
                                    null,
                                    "statement"
                                ),
                                required: true,
                                className: "forms__input--radio"
                            }}
                            label={{
                                text: contentForm.fields.acceptStatement.label,
                                links: [
                                    {
                                        link:
                                            config.services.userForm.urls
                                                .healthStatement,
                                        linkText:
                                            config.services.userForm.titles
                                                .healthStatement
                                    }
                                ]
                            }}
                            options={[
                                {
                                    value: "true",
                                    text: "Yes"
                                },
                                {
                                    value: "false",
                                    text: "No"
                                }
                            ]}
                            name={"radio_statement_" + index}
                            radioLabelClassName={
                                "personal-details__radio-label"
                            }
                            type="radio"
                            valid={
                                this.props.validationErrors &&
                                this.props.validationErrors.statement &&
                                (!user.info ||
                                    !user.info.statement ||
                                    !user.info.statement.valid)
                                    ? false
                                    : !user.info ||
                                      !user.info.statement ||
                                      user.info.statement.valid
                            }
                            value={
                                user.info.statement && user.info.statement.value
                            }
                        />
                    </li>
                    <li>
                        <Input
                            className="personal-details__input--radio"
                            input={{
                                onChange: this.props.onChangeInput.bind(
                                    null,
                                    "tandc"
                                ),
                                required: true,
                                className: "forms__input--radio"
                            }}
                            label={{
                                text: contentForm.fields.acceptTandc.label,
                                links: [
                                    {
                                        link:
                                            config.services.userForm.urls.Tandc,
                                        linkText:
                                            config.services.userForm.titles
                                                .Tandc
                                    },
                                    {
                                        link:
                                            config.services.userForm.urls
                                                .privacy,
                                        linkText:
                                            config.services.userForm.titles
                                                .privacy
                                    }
                                ]
                            }}
                            options={[
                                {
                                    value: "true",
                                    text: "Yes"
                                },
                                {
                                    value: "false",
                                    text: "No"
                                }
                            ]}
                            name={"radio_tandc_" + index}
                            radioLabelClassName={
                                "personal-details__radio-label"
                            }
                            type="radio"
                            valid={
                                this.props.validationErrors &&
                                this.props.validationErrors.tandc &&
                                (!user.info ||
                                    !user.info.tandc ||
                                    !user.info.tandc.valid)
                                    ? false
                                    : !user.info ||
                                      !user.info.tandc ||
                                      user.info.tandc.valid
                            }
                            value={user.info.tandc && user.info.tandc.value}
                        />
                    </li>
                </ul>

                {marketingPrefs && (
                    <p className="personal-details__info">
                        {contentForm.fields.marketingGuest.marketingNote}
                    </p>
                )}
            </div>
        );

        if (user.isLoggedInUser) {
            logoutButton = (
                <button
                    className="personal-details__logout"
                    onClick={this.props.logoutUser.bind(null, this.props.index)}
                >
                    Log out
                </button>
            );
        } else {
            logoutButton = null;
        }

        if (this.props.isStaff) {
            staffDisclaimer = (
                <div className="personal-details__staff-disclaimer">
                    <span className="form__error">
                        <strong className="form__error-title">
                            {contentForm.fields.staffDisclaimer.title}
                        </strong>
                        {contentForm.fields.staffDisclaimer.message}
                    </span>

                    <Input
                        className="personal-details__input--checkbox"
                        input={{
                            onChange: this.props.onChangeInput.bind(
                                null,
                                "staffDisclaimer"
                            ),
                            className: "forms__input--checkbox"
                        }}
                        label={{
                            text: contentForm.fields.staffDisclaimer.label
                        }}
                        name={"radio_staffDisclaimer_" + index}
                        type="checkbox"
                        inputFirst={true}
                        valid={
                            this.props.validationErrors &&
                            this.props.validationErrors.staffDisclaimer &&
                            (!user.info ||
                                !user.info.staffDisclaimer ||
                                !user.info.staffDisclaimer.valid)
                                ? false
                                : !user.info ||
                                  !user.info.staffDisclaimer ||
                                  user.info.staffDisclaimer.valid
                        }
                        value={
                            user.info.staffDisclaimer &&
                            user.info.staffDisclaimer.value
                        }
                    />
                </div>
            );
        }

        if (useLoginForm && !basicDetailsFrozen) {
            formContent = (
                <div className="module-container">
                    <div
                        className={
                            "module module--inactive" +
                            (basicDetailsFrozen
                                ? " personal-details__user-section--frozen"
                                : "")
                        }
                    >
                        <h2
                            className={
                                "module__title personal-details__user-title memberships__type memberships__type--" +
                                user.type
                            }
                        >
                            {this.props.userName}
                            {unfreezeButton}
                        </h2>
                        {facilityDetails}
                        {toggle}
                        {loginForm}
                    </div>
                </div>
            );
        } else {
            formContent = (
                <div className="module-container">
                    <div
                        className={
                            "module module--inactive" +
                            (basicDetailsFrozen
                                ? " personal-details__user-section--frozen"
                                : "")
                        }
                    >
                        <h2
                            className={
                                "module__title personal-details__user-title memberships__type memberships__type--" +
                                user.type
                            }
                        >
                            {this.props.userName} {logoutButton}
                            {unfreezeButton}
                        </h2>
                        {facilityDetails}
                        {toggle}
                        <div className="personal-details__container">
                            {basicDetailsTitle}
                            {basicDetailsSection}
                        </div>
                    </div>

                    <div
                        className={
                            "module module--inactive" +
                            (contactDetailsFrozen
                                ? " personal-details__user-section--frozen"
                                : "")
                        }
                    >
                        <div className="personal-details__container">
                            {contactDetailsTitle}
                            {contactDetailsSection}
                        </div>
                    </div>

                    {emmergencyDetailsBlock}
                    {medicalConditionsBlock}
                    {concessionBlock}

                    <div className={"module module--inactive"}>
                        <div className="personal-details__container">
                            {subscriptionsTitle}
                            {subscriptionsSection}
                            {staffDisclaimer}
                        </div>
                    </div>
                </div>
            );
        }

        return formContent;
    }
});

UserForm.propTypes = {
    contentForm: React.PropTypes.object,
    contentLoginForm: React.PropTypes.object,
    contentToggle: React.PropTypes.object,
    durationType: React.PropTypes.number,
    duration: React.PropTypes.number,
    index: React.PropTypes.number,
    isBooking: React.PropTypes.bool,
    logoutUser: React.PropTypes.func,
    onBlurInput: React.PropTypes.func,
    onChangeInput: React.PropTypes.func,
    onUnfreeze: React.PropTypes.func,
    onUnfreezeSection: React.PropTypes.func,
    onUseManualEntry: React.PropTypes.func,
    onUseLeadAddress: React.PropTypes.func,
    titles: React.PropTypes.object,
    unfrozenSections: React.PropTypes.object,
    useLeadAddress: React.PropTypes.bool,
    useLeadEmmergencyDetails: React.PropTypes.bool,
    user: React.PropTypes.object,
    userFirstName: React.PropTypes.string,
    userName: React.PropTypes.string,
    validationErrors: React.PropTypes.object
};

module.exports = connect(state => ({
    concessionMessage: state.app.content.notices.concession.message,

    // Type of membership (adult, concession etc)
    // ONLY execute this in joinonline, not lessons online
    doesRequireProofOfEligibility: user =>
        !state.lessons.inView && requiresProofOfEligibility(user)
}))(UserForm);
