var React               = require('react'),
    connect             = require('react-redux').connect,
    actions             = require('../../../actions'),
    membershipsActions  = require('../../../actions/memberships.js'),
    Faqs                = require('../../faqs'),

    config              = require('../../../config'),
    Button              = require('../../common/button'),

    utils               = require('../../../utils/'),

    UserDetails         = require('./userDetails'),
    EligibilityForm     = require('./eligibilityForm'),
    LevelBreakdown      = require('./levelBreakdown'),
    ChosenLevel         = require('./chosenLevel'),


    scrollToComponent = require('react-scroll-to-component'),

    LevelIndex;

LevelIndex = React.createClass({

    getInitialState() {
        return {
            distanceConfirmed: false,
            eligibilityOptions: this.props.eligibilityOptions,
            singleUserType: null,
            user: this.props.lessons.user,
            validationErrors: {},
            valid: false,
            showAddUserBox: false
        };
    },

    componentDidMount() {
        this.setNewProps(this.props);

        scrollToComponent(document.getElementById('joinonline-app'), {
            align: 'top',
            offset: 0
        });
    },

    componentWillReceiveProps(nextProps) {
        this.setNewProps(nextProps);
    },

    setNewProps: function setNewProps(props) {
        var promises = [],
            lessons = this.props.lessons;

        //if the user is not logged in, validate their details
        if(!props.lessons.user.loggedInUser) {
            if(props.lessons.user.info && (props.lessons.user.info.firstName || props.lessons.user.info.dob)) {
                promises.push(actions.lessons.validateUser(props.lessons.user, props.lessons, props.centre.info.site_id, true));
            }

            Promise.all(promises)
                .then(function (result) {
                    var errors = {},
                        valid = false;

                    if(result.length) {
                        errors = result[0].errors;
                        valid = result[0].valid;
                    }

                    this.setState({
                        validationErrors: errors,
                        valid: valid
                    });
                }.bind(this));

            this.setState({
                eligibilityOptions: props.eligibilityOptions,
                user: props.lessons.user
            });
        } else {
            this.setState({
                eligibilityOptions: props.eligibilityOptions,
                user: props.lessons.user,
                valid: true
            });
        }

        //format the price object - this will let us know if there are different types of users
        //eg. if both 'Adults' and 'juniors' are eligible for the lesson type
        var weekdays = this.props.general.dayNamesLong;
        var prices = utils.formatLessonPrice(lessons, weekdays);

        if(prices && Object.keys(prices).length === 1 && (!this.state.singleUserType || !props.lessons.user.id)) {
            var siteId = this.props.centre.info.site_id,
                type = Object.keys(prices)[0],
                userType = this.props.content.userTypes[type].replace('0001', siteId);

            this.onUpdateUser('id', userType);

            this.setState({
                singleUserType: userType
            });
        }

    },

    //go to next stage (Select Lessons)
    confirmNext: function confirmNext() {
        this.onResetEligibility();

        this.props.dispatch(actions.lessons.clearEligibilityUser());
        this.props.dispatch(actions.stages.next());
    },

    //if the eligibility object includes age validation
    //return a level obj depending on the age of the user
    getLevelByAge: function getLevelByAge(ages) {
        var user = this.state.user;

        for(var a = 0; a < ages.length; a++) {
            var ageOption = ages[a];

            if(user.info.ageInMonths >= ageOption['min'] && user.info.ageInMonths <= ageOption['max']) {
                var levelObj = {
                        level: ageOption.level,
                        fallback: ageOption.fallback || null,
                        fallback2: ageOption.fallback2 || null,
                        static: ageOption.static || false
                    };

                return levelObj;
            }
        }
    },

    //if the eligibility object includes distance validation
    //return a level obj depending on the distance the user has input
    getDistanceOptions: function getDistanceOptions(criteria, value) {
        for(var o = 0; o < criteria.length; o++) {
            var distanceOption = criteria[o];

            if(value > distanceOption['min'] && value <= distanceOption['max']) {
                if(distanceOption.journey) {
                    return {
                        journey: distanceOption.journey
                    };

                //if theres a level, return the level directly
                } else if(distanceOption.level) {
                    var levelObj = {
                        level: distanceOption.level,
                        fallback: distanceOption.fallback || null,
                        static: distanceOption.static || false
                    };

                    return levelObj;

                //if the distance object also has age validation
                //pass the object to the getLevelByAge function
                } else if(distanceOption.ages) {
                    return this.getLevelByAge(distanceOption.ages);
                } else {
                    return null;
                }
            }
        }
    },

    //on click of 'nextStageButton' button
    next: function next(e) {
        e.preventDefault();

        //if the user hasn't selected a level
        //ask them to confirm their selection before continueing
        //Iterate over the users list, and check that all of the users that have lessons,
        //have selected a session for that lesson.
        const hasAllSelections = this.props.users.objects.every(u => {
            if(u.lesson && u.lesson.selectedLessons){
                return Object.entries(u.lesson.selectedLessons).length > 0;
            }

            return false;
        });

        if(!hasAllSelections) {
            this.props.dispatch(actions.app.showModal('confirmLevel', {callback: this.confirmNext}));
        } else {
            this.confirmNext();
            this.props.dispatch(membershipsActions.freeze());
            this.props.dispatch(membershipsActions.selectMemberships());
        }
    },

    //show the next module after completeing the users details section
    nextModule: function nextModule(e) {
        var user = this.state.user,
            lessons = this.props.lessons,
            promises = [],
            lessonType = lessons.isMulti && lessons.category ? lessons.category : utils.getLessonType(lessons),
            lessonTypeId = lessons.typeId,
            siteId = this.props.centre.info.site_id;

        if(!user.loggedInUser) {
            //check validitiy of user
            promises.push(actions.lessons.validateUser(user, lessons, siteId, true));

            Promise.all(promises)
                .then(function (result) {
                    //if the user is valid,
                    //fetch the eligibility options for them using their age/category type
                    console.log('result', result[0]);
                    if (result[0].valid) {
                        this.setState({
                            valid: result[0].valid
                        });

                        this.props.dispatch(dispatch => {
                            const getEligibility = actions.lessons.getEligibility(lessonTypeId, lessonType, user.info.ageInMonths);

                            return getEligibility(dispatch).then((response)=> {
                                if (response.level) {
                                    this.setState({
                                        showAddUserBox: false
                                    });
                                }

                                return response;
                            });
                        });
                    } else {
                        this.setState({
                            validationErrors: result[0].errors,
                            valid: result[0].valid
                        });
                    }
                }.bind(this));
        } else {
            //if the user is logged in, they are automatically valid

            this.setState({
                valid: true
            });

            this.props.dispatch(actions.lessons.getEligibility(lessonTypeId, lessonType, user.info.ageInMonths));
        }

        e.preventDefault();
    },

    generateAgeErrorMessage: function generateAgeErrorMessage() {
        const validation = this.props?.lessons?.eligibilityOptions;
        if (!validation) {
            return;
        }

        if ((this.state.user.ageInMonths >= validation.min) && (user.ageInMonths <= validation.max)) {
            return;
        }

        if (validation.min === -1 || validation.max === -1) {
           return this.props.content.ageEligibility.Invalid
        }

        const yearsOrMonths = validation.min >= 36 ? validation.min / 12 + ' years' : validation.min + ' months';
        return this.state.user.ageInMonths < validation.min ? `Student does not meet minimum age for these lessons - minimum age is ${yearsOrMonths}` : `Student exceeds maximum age for these lessons`;
    },

    //add a new level from the unlockable levels
    onAddLesson: function onAddLesson(level, levelName, value) {
        var selectedLessons = this.state.user.selectedLessons,
            userData =  {
                chosenLevel: level,
                ineligible: false,
                levelName: levelName
            };

        //level doesn't exist and value is true
        //add the new level
        if(!selectedLessons[level] && value) {
            //fetch all the available classes and times for the selected level
            //in preparation for the next stage
            this.props.dispatch(actions.lessons.getClassesByLevel(this.props.centre, userData));
            this.props.dispatch(actions.lessons.addNewLevel(level, levelName));

        //level exists and value is false
        //remove the level
        } else if(selectedLessons[level] && !value) {
            this.props.dispatch(actions.lessons.removeLevel(level));
        }
    },

    //on change input - userDetails form
    onChangeInput: function onChangeInput(field, value) {
        var data = { info: {} };
        data.info[field] = value;
        // data.convertMonthsToYears = true;

        this.props.dispatch(actions.lessons.changeData(data));
    },

    //when a user interacts with the eligibility form
    //set the recommended level based on their choices
    onChangeEligibility: function onChangeEligibility(field, options, value) {
        if(options) {
            if(options[value]) {
                //if a level exists, set that as the recommended level
                if(options[value].level) {
                    var levelObj = {
                            level: options[value].level,
                            fallback: options[value].fallback || null,
                            static: options.static || options[value].static || false
                        };

                    this.setRecommendedLevel(levelObj);

                //if age validation exists, check against the users age
                } else if(options[value].ages) {
                    this.setRecommendedLevel(this.getLevelByAge(options[value].ages));
                }
            }
        }

        if(field === 'distance') {
            this.props.dispatch(actions.lessons.clearLevels());

            this.setState({
                distanceConfirmed: false
            });
        }

        var data = { options: {} };
        data.options[field] = value;

        this.props.dispatch(actions.lessons.setUserEligibility(data));
    },

    //reset all eligibility fields
    onResetEligibility: function onResetEligibility() {
        var userData = this.props.lessons.user;

        this.setState({
            singleUserType: null,
            showAddUserBox: true
        });

        this.props.dispatch(actions.lessons.resetEligibility(userData));
    },

    //whne a user selects a level from the levelbreakdown module
    onSelectLevel: function onSelectLevel(value) {
        var user = this.props.lessons.user,
            levelName,
            lessonType;

        //set the level name and type
        for(var i = 0; i < user.levels.length; i++) {
            if(value === user.levels[i].mrm_stage_id) {
                levelName = user.levels[i].title;
                lessonType = user.levels[i].lesson_type_id;
                break;
            }
        }

        if(!levelName) {
            levelName = value;
        }

        var userData = {
            chosenLevel: value,
            ineligible: false,
            levelName: levelName,
            lessonType: lessonType
        };

        //fetch all the available classes and times for the selected level
        //in preparation for the next stage
        this.props.dispatch(actions.lessons.clearSelections());

        var promise = [];

        promise.push(this.props.dispatch(actions.lessons.getUnlockedLevels(value)));
        this.props.dispatch(membershipsActions.selectMemberships());

        Promise.all(promise)
            .then(function () {
                this.props.dispatch(actions.lessons.getClassesByLevel(this.props.centre, userData));

                this.setState({
                    showAddUserBox: false
                });
            }.bind(this));
    },

    //when distance is updated and if no other questions,
    //set recommended level
    onUpdateDistance: function onUpdateDistance(options) {
        var distanceOption = this.getDistanceOptions(options.criteria, this.state.user.eligibility.distance);

        if(distanceOption) {
            if(distanceOption.journey) {
                this.props.dispatch(actions.lessons.updateUser({
                    chosenLevel: null,
                    ineligible: true,
                    altJourney: distanceOption.journey,
                    recommendedLevel: null
                }));
            } else {
                this.props.dispatch(actions.lessons.updateUser({altJourney: null}));
                this.setRecommendedLevel(distanceOption);
            }
        }

        this.setState({
            distanceConfirmed: true
        });
    },

    //when a users details are updated
    onUpdateUser: function onUpdateUser(field, value) {
        var user = Object.assign({}, this.state.user),
            loggedInUser = this.props.loggedInUser,
            users = this.props.users,
            isLoggedIn = false;

        user[field] = value;

        //if a loggedin user exists and the chosen user is an adult and no other user exists
        //set them as the loggedin user
        if(loggedInUser.loggedIn && value === '0000-ADULT' && (!users.count[value] || users.count[value] === 0)) {
            isLoggedIn = true;
        }

        this.props.dispatch(actions.lessons.resetUser(user, field, isLoggedIn));

        var breakdown = document.getElementById('userDetails');
        scrollToComponent(breakdown, {
            align: 'top',
            offset: -100
        });
    },

    //set the recommended level for the user
    setRecommendedLevel: function setRecommendedLevel(levelObj) {
        var user = Object.assign({}, this.state.user),
            isStatic = levelObj.static;

        user['recommendedLevel'] = levelObj.level;

        this.props.dispatch(actions.lessons.setRecommendedLevel(user, 'recommendedLevel', levelObj, isStatic));
    },

    render: function () {
        var content = this.props.content,
            lessons = this.props.lessons,
            users = this.props.users.objects,
            eligibilityOptions = this.state.eligibilityOptions,
            addUserButtonProps = {
                className: 'button button--change',
                onClick: this.onResetEligibility
            },
            nextStageButtonProps = {
                className: 'button',
                onClick: this.next
            },
            user = this.state.user,
            lessonCategory = lessons.isMulti && lessons.category ? lessons.category : utils.getLessonType(lessons),
            lessonType = utils.getLessonType(lessons),
            lessonName = utils.getLessonType(lessons, true),
            showEligibility = this.state.valid && this.props.lessons.user.id && eligibilityOptions && !lessons.isGeneric,
            enquiriesChoices = config.services.lessons.enquiries[lessonType] || config.services.lessons.enquiries['default'],
            enquiryLink = config.services.lessons.urls.enquiry + enquiriesChoices + '&siteid=' + this.props.centre.info.site_id,
            showGenericError = lessons.isGeneric && eligibilityOptions && !eligibilityOptions.min,
            staffLogin = !!this.props.staffId,
            eligibilityView,
            levelBreakdown,
            chosenLevel,
            errorMessage,
            ageErrorMessage,
            extraOptions,
            addUserButton,
            validUsers,
            nextStageButton;

        //make sure the only user is not a free user
        if(users && users.length) {
            var keys = Object.keys(this.props.users.count),
                usersCount = Object.assign({}, this.props.users.count),
                freeOnly = false,
                hasOthers = false;

            for (var i = 0; i < keys.length; i++) {
                if(/freeprofile/.test(keys[i].toLowerCase()) && usersCount[keys[i]]) {
                    freeOnly = true;
                    break;
                }

                if (!/freeprofile/.test(keys[i].toLowerCase()) && usersCount[keys[i]]) {
                    hasOthers = true;
                    break;
                }
            }

            validUsers = (users.length > 1) || (!freeOnly && hasOthers) ? true : false;
        }

        //show buttons block
        //if users are all valid or a level has been chosen
        if(validUsers || user.chosenLevel) {
            //only show the addUserButton if
            //the current user has a level selected
            if(!this.state.showAddUserBox) {
                addUserButton = <Button {...addUserButtonProps}>{ content.main.buttonAddUser }</Button>;
            }

            nextStageButton = <Button {...nextStageButtonProps}>{ content.main.buttonNext }</Button>;

            if(validUsers || !user.ineligible) {
                extraOptions = <div className='stage-options stage-options--levels'>
                        { addUserButton }
                        { nextStageButton }
                    </div>;
            }
        }


        if (showGenericError && this.props.eligibilityOptions?.errorMessage) {
            ageErrorMessage = <div className='stage-options'>
                <span className='form__error form__error--lessons'>
                    {this.props.eligibilityOptions?.errorMessage}
                </span>
            </div>;
        }

        //if the user is ineligible for any level at the centre
        //and there are no levels that have been unlocked, show error
        if (user.ineligible) {
            //if action is required
            //eg. telling the customer to go on a different lesson journey
            if (!user.chosenLevel && !user.recommendedLevel && user.altJourney) {
                errorMessage = <div className='stage-options'>
                    <span className='form__error form__error--lessons' dangerouslySetInnerHTML={{ __html: content.disclaimers.changeLessonJourney.replace('{name}', user.info.firstName.value).replace('{lesson}', lessonName).replace('{alternative}', user.altJourney) }}>
                    </span>
                </div>;

                //if no alternative levels have been unlocked
            } else if (!user.unlockedLevels && this.props.eligibilityOptions?.errorMessage) {
                errorMessage = <div className='stage-options'>
                    <span className='form__error form__error--lessons'>
                        {this.props.eligibilityOptions?.errorMessage}
                    </span>
                </div>;
            }

            nextStageButton = <Button className="button button--disabled" disabled={true}>{ content.main.buttonNext }</Button>;
            extraOptions = <div className='stage-options stage-options--levels'>
                        { nextStageButton }
                    </div>;

        //if the users chosen center does not have the correct subscription, show the 'errorWithCentre'
        } else if (lessons.errors && lessons.errors.setLevel) {
            errorMessage = <div className='stage-options'>
                <span className='form__error form__error--lessons' dangerouslySetInnerHTML={ { __html:content.disclaimers.errorWithCentre.replace('{link}', enquiryLink) } }>
                </span>
            </div>;

            //hide the buttons block
            extraOptions = null;
        } else if(lessons.errors && lessons.errors.setAgeError && this.props.eligibilityOptions?.errorMessage) {
            ageErrorMessage = <div className='stage-options'>
                <span className='form__error form__error--lessons'>
                    {this.props.eligibilityOptions?.errorMessage}
                </span>
            </div>;

            //hide the buttons block
            extraOptions = null;
        }

        //if a user has complete the 'UserDetails' form
        //and if the users info makes them eligible to find a level
        if(showEligibility) {
            //if there are extra questions needed to determine a users level
            if(eligibilityOptions.question) {
                eligibilityView = (<EligibilityForm
                        activeModule={ !user.levels }
                        content={ content.eligibilityForm[lessonCategory] }
                        disclaimers={ content.disclaimers }
                        distanceConfirmed={ this.state.distanceConfirmed }
                        eligibilityOptions={ eligibilityOptions }
                        enquiryLink={ enquiryLink }
                        lessonCategory={ lessonCategory }
                        lessonName={ lessonName }
                        links={ content.links }
                        onChangeEligibility={ this.onChangeEligibility }
                        onUpdateDistance={ this.onUpdateDistance }
                        setLevel={ this.setLevel }
                        user={ user }
                    />);
            }


        }

        if(showEligibility || lessons.isGeneric) {
            //if a user has been assigned a recommended level
            if(user.levels && !user.staticResult) {
                levelBreakdown = (<LevelBreakdown
                        dispatch= {this.props.dispatch}
                        breakpoint={ this.props.breakpoint }
                        content={ content.levelBreakdown }
                        enquiryLink={ enquiryLink }
                        onSelectLevel={ this.onSelectLevel }
                        links={ content.links }
                        loggedInUser={ this.props.loggedInUser }
                        user={ user }
                        lessons={ this.props.lessons }
                        isGeneric={ lessons.isGeneric }
                        staffLogin={ staffLogin }
                    />);
            }

            //if a user has chosen a level
            if((user.chosenLevel && user.levelName && !user.ineligible) ||
                (user.unlockedLevels && !user.altJourney && (!user.levels || (user.levels && user.levels.length === 1)))) {
                chosenLevel = (<ChosenLevel
                        content={ content.levelBreakdown }
                        disclaimers={ content.disclaimers }
                        errors={ lessons.errors }
                        levelInfo={ lessons.levelInfo }
                        lessonCategory={ lessonCategory }
                        lessonType={ lessonType }
                        lessonName={ lessonName }
                        lessons={ lessons }
                        loggedInUser={ this.props.loggedInUser }
                        onAddLesson={ this.onAddLesson }
                        user={ user }
                    />);
            }
        }

        return (<div>

            {(this.state.showAddUserBox || !validUsers) &&
                <UserDetails
                    activeModule={ !showEligibility }
                    centre={ this.props.centre }
                    content={ content.userDetails }
                    lessonType={ this.props.lessons.lessonType }
                    lessonName={ lessonName }
                    loggedInUser={ this.props.loggedInUser }
                    onChangeInput={ this.onChangeInput }
                    onUpdateUser={ this.onUpdateUser }
                    nextModule={ this.nextModule }
                    singleUserType={ this.state.singleUserType }
                    user={ user }
                    valid={ this.state.valid }
                    validationErrors={{ ...this.state.validationErrors, dob: this.state.user?.info?.dob?.validationErrors }}
                    isGeneric={lessons.isGeneric}
                    showGenericError={showGenericError}
                    />
            }
            { ageErrorMessage }
            { eligibilityView }
            { levelBreakdown }
            { chosenLevel }
            { errorMessage }
            { extraOptions }
            <Faqs />
        </div>);
    }
});

function mapStateToProps(state) {
    return {
        breakpoint: state.app.breakpoint,
        centre: state.centreFinder.selected,
        content: state.app.content.lessons.levels,
        general: state.app.content.general,
        eligibilityOptions: state.lessons.eligibilityOptions,
        lessons: state.lessons,
        loggedInUser: state.user,
        users: state.selections.users,
        staffId: state.staffLogin.staffId,
    };
}

module.exports = connect(mapStateToProps)(LevelIndex);
