var React           = require('react'),
    connect         = require('react-redux').connect,

    actions         = require('../../actions'),

    SidebarCentreInfo;

SidebarCentreInfo = React.createClass({
    _handleChangeClick: function _handleChangeClick(e) {
        e.preventDefault();
        this.props.dispatch(actions.app.showModal('confirmCentreReset'));
    },

    render: function render() {
        if (!this.props.centre || !this.props.centre.info || (!this.props.centre.info.name && !this.props.centre.info.siteDesc)) {
            return null;
        }

        var content = this.props.content;
        const councilText = this.props.centre.info.agency_model ? content.councilTextAgencyModel : content.councilTextPartnershipModel;
        const council = councilText.replace('{council}', this.props.centre.info.council);

        return (
            <div className="sidebar-selected-centre">
                <p className="sidebar-selected-centre__title">{ content.title }</p>
                <h3 className="sidebar-selected-centre__name">{ this.props.centre.info.name || this.props.centre.info.siteDesc }</h3>
                <p className="sidebar-selected-centre__council">{council}</p>
            </div>
        );
    }
});

SidebarCentreInfo.propTypes = {
    centre: React.PropTypes.object,
    content: React.PropTypes.object
};

module.exports = connect()(SidebarCentreInfo);
